import { AnyAction } from 'redux'

import { ContentStateType } from 'data/types'
import { shuffleArray } from 'utils/shuffleArray'
import { CONTENT_DATA_LOAD, CONTENT_SHUFFLE_PROJECTS } from './types'

const initialState: ContentStateType = {
  projects: [],
  comments: [],
}

export default function (
  state = initialState,
  action: AnyAction
): ContentStateType {
  switch (action.type) {
    case CONTENT_DATA_LOAD: {
      const { projects, comments } = action.payload

      if (projects || comments) {
        return {
          ...state,
          projects,
          comments,
        }
      }

      return state
    }

    case CONTENT_SHUFFLE_PROJECTS: {
      const projects = [...state.projects]
      shuffleArray(projects)

      return {
        ...state,
        projects,
      }
    }

    default:
      return state
  }
}
