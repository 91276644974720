import React, { ReactElement, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'

import { RootState } from 'data/redux/rootReducer'
import { uiChangeIntro } from 'data/redux/ui/actions'
import { motionIntroVariants } from 'utils/motionVariants'
import IntroAnimation from './IntroAnimation'

import './Intro.scss'

const mapStateToProps = (state: RootState) => ({
  showIntro: state.ui.showIntro,
})

const mapDispatchToProps = {
  actionChangeIntro: uiChangeIntro,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type IntroProps = ConnectedProps<typeof connector>

let timeout = null

const Intro = ({ showIntro, actionChangeIntro }: IntroProps): ReactElement => {
  const [speed, setSpeed] = useState(1)
  const { formatMessage } = useIntl()

  useEffect(() => {
    actionChangeIntro(true)
  }, [])

  useEffect(() => {
    if (showIntro) {
      document.body.classList.add('h-intro')
    } else {
      document.body.classList.remove('h-intro')
    }
  }, [showIntro])

  const handleClick = () => actionChangeIntro(false)

  const handleWheel = () => {
    setSpeed(10)
    clearTimeout(timeout)
    timeout = setTimeout(() => setSpeed(1), 500)
  }

  const textHeader = formatMessage({
    id: 'Intro_Header',
    defaultMessage: 'Willkommen bei der digitalen Publikation zur Ausstellung:',
  })

  const textEnter = formatMessage({
    id: 'Intro_Enter',
    defaultMessage: 'Eintreten',
  })

  return (
    <AnimatePresence>
      {showIntro && (
        <motion.section
          className="Intro"
          onWheel={handleWheel}
          onTouchMove={handleWheel}
          onClick={handleClick}
          variants={motionIntroVariants}
          initial="initial"
          exit="exit"
        >
          <IntroAnimation speed={speed} />

          <div className="Intro__header">{textHeader}</div>

          <div className="Intro__title">
            Planet
            <br />
            Digital
          </div>

          <button className="Intro__enter">{textEnter}</button>
        </motion.section>
      )}
    </AnimatePresence>
  )
}

export default connector(Intro)
