import { Window } from 'happy-dom'

export const addThumbsToProjectLinks = (html, projects) => {
  if (html === null) return html

  const window = new Window()
  const document = window.document

  document.body.innerHTML = html

  const projectLinks = document.querySelectorAll('.h-link-project')

  for (const project of projectLinks) {
    const href = project.getAttribute('href')
    const found = href.match(/\/(projekt|project)\/(.*)/)

    // 1. get slug
    if (found && found.length > 2) {
      const findProject = projects.find((item) => item.slug === found[2])

      // 2. find project
      if (findProject) {
        // 3. find thumb
        const thumbFirst =
          findProject.thumb &&
          findProject.thumb.length > 0 &&
          findProject.thumb[0]

        if (thumbFirst) {
          project.innerHTML = `<span class="h-link-project-title">${project.innerHTML}</span>`

          const img = document.createElement('img') as any
          img.className = 'h-link-project-thumb'
          img.src = thumbFirst.url
          project.appendChild(img)
        }
      }
    }
  }

  return document.body.innerHTML
}
