import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

import { BASE_URL } from 'Constants'
import { TagType } from 'data/types'
import Helmet from 'view/components/helmet/DefaultHelmet'
import TagEvents from './TagEvents'
import TagExcerpts from './TagExcerpts'
import TagMoreTags from './TagMoreTags'
import TagProjects from './TagProjects'
import TagTopic from './TagTopic'

import './Tag.scss'

interface TagProps extends TagType {
  locale: string
  tags: any
}

const Tag = ({
  locale,
  tags,
  id,
  slug,
  title,
  topic,
  excerpts,
  relatedProjects,
  relatedEvents,
}: TagProps): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const { formatMessage } = useIntl()

  const state = location.state as { backgroundLocation?: Location }

  const textHeaderTitle = formatMessage({
    id: 'Tag_Header_Title',
    defaultMessage: 'Ergebnisse für',
  })

  const textClose = formatMessage({
    id: 'Tag_Close',
    defaultMessage: 'Schliessen',
  })

  // navigate back or home
  const actionCloseClick = () => {
    if (state?.backgroundLocation) {
      navigate(-1)
    } else {
      navigate(`/${locale}`)
    }
  }

  const helmetParams = {
    title: title,
    description: title,
    url: `${BASE_URL}/${locale}/tag/${slug}`,
  }

  return (
    <>
      <Helmet {...helmetParams} />
      <aside className="Tag">
        <div className="Tag__header">{textHeaderTitle}</div>

        <div className="Tag__content">
          <TagTopic topic={topic} />
          <TagProjects
            locale={locale}
            title={title}
            projects={relatedProjects}
          />
          <TagExcerpts locale={locale} excerpts={excerpts} />
          <TagEvents locale={locale} events={relatedEvents} />
          <TagMoreTags locale={locale} id={id} tags={tags} />
        </div>

        <div className="Tag__footer">
          <button className="Tag__footer__button" onClick={actionCloseClick}>
            {textClose}
          </button>
        </div>
      </aside>
    </>
  )
}

export default Tag
