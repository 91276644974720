import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import classnames from 'classnames'

import { RootState } from 'data/redux/rootReducer'
import BlockExpanderItem from './BlockExpanderItem'

import './BlockExpander.scss'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface BlockExpanderProps extends PropsFromRedux {
  locale: string
  paddingTop: string
  title: string
  related: any[]
}

const BlockExpander = ({
  locale,
  projects,
  paddingTop,
  title,
  related,
}: BlockExpanderProps): ReactElement => {
  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockExpander'
  )

  const items = related.map((item) => (
    <BlockExpanderItem
      key={item.id}
      locale={locale}
      projects={projects}
      {...item}
    />
  ))

  return (
    <div className={classes}>
      {title && <div className="BlockExpander__title">{title}</div>}
      <div className="BlockExpander__contents">{items}</div>
    </div>
  )
}

export default connector(BlockExpander)
