import 'intersection-observer'
import React, { CSSProperties, ReactElement, useState } from 'react'
import classnames from 'classnames'
import { useInView } from 'react-intersection-observer'

import { ImageType } from 'data/types'

import './Image.scss'

type ImageProps = {
  image: ImageType
  className?: string
  style?: CSSProperties
  onLoad?: () => void
}

const Image = ({
  image,
  className,
  style,
  onLoad,
}: ImageProps): ReactElement => {
  const [loaded, setLoaded] = useState(false)
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  function onLoadFunc() {
    if (!inView) {
      return
    }

    setLoaded(true)
    onLoad && onLoad()
  }

  const smallestImgUrl =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

  const classes = classnames('Image', { 'Image--fadein': loaded }, className)

  return (
    <img
      ref={ref}
      className={classes}
      style={{ ...style }}
      key={image.url}
      alt={image.title}
      width={image.width}
      height={image.height}
      src={(inView && image.url) || smallestImgUrl}
      data-src={image.url}
      onLoad={onLoadFunc}
    />
  )
}

export default Image
