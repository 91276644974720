import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const TagMoreTags = ({ locale, id, tags }): ReactElement => {
  const { formatMessage } = useIntl()

  const textMoreTagsTitle = formatMessage({
    id: 'Tag_More_Tags_Title',
    defaultMessage: 'Weitere Begriffe',
  })

  const moreItems = tags.map((item) => {
    if (item.id === id) return null

    return (
      <div key={item.id} className="Tag__more">
        <Link
          to={`${
            locale === 'en' ? `/en/tag/${item.slug}` : `/de/tag/${item.slug}`
          }`}
        >
          {item.title}
        </Link>
      </div>
    )
  })

  return (
    <div className="Tag__section">
      <div className="Tag__section__title">{textMoreTagsTitle}</div>
      <div className="Tag__section__content h-type-more">{moreItems}</div>
    </div>
  )
}

export default TagMoreTags
