import 'intersection-observer'
import React, { useRef, useEffect, ReactElement } from 'react'
import { useInView } from 'react-intersection-observer'
import classnames from 'classnames'

import { VideoType } from 'data/types'

import './Video.scss'

type Props = {
  video: VideoType
  width?: number
  height?: number
  ratio?: number
  threshold?: number
  classNames?: string[]
}

const Video = ({
  video,
  width,
  height,
  ratio,
  threshold,
  classNames,
}: Props): ReactElement | null => {
  const [ref, inView] = useInView({
    threshold: threshold || 0.5,
  })
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    let isCancelled = false

    if (!isCancelled) {
      try {
        if (videoRef && videoRef.current) {
          if (inView) {
            videoRef.current.play()
          } else {
            videoRef.current.pause()
          }
        }
      } catch (e) {
        if (!isCancelled) {
          throw e
        }
      }
    }

    return () => {
      isCancelled = true
    }
  }, [videoRef, inView])

  if (!video) {
    return null
  }

  const classes = classnames(...(classNames || []), 'Video')

  let usedRatio = ratio || 1

  if (!ratio && width && height) {
    usedRatio = height / (width > 0 ? height : 1)
  }

  return (
    <div ref={ref} className={classes}>
      <div
        className="Video__ratio"
        style={{ paddingTop: `${100 * usedRatio}%` }}
      />
      <video
        className="Video__video"
        ref={videoRef}
        muted
        loop
        playsInline
        autoPlay
      >
        <source src={video.url} />
      </video>
    </div>
  )
}

export default Video
