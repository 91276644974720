import React, {
  MouseEvent,
  TouchEvent,
  ReactElement,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { VideoType } from 'data/types'
import { formatTime } from 'utils/formatTime'

import './VideoPlayer.scss'

type Props = {
  video: VideoType
  ratio: number
}

const VideoPlayer = ({ video, ratio }: Props): ReactElement => {
  const { formatMessage } = useIntl()

  const videoRef = useRef<HTMLVideoElement>(null)
  const seekRef = useRef<HTMLInputElement>(null)
  const seekTooltipRef = useRef<HTMLDivElement>(null)
  const [firstRun, setFirstRun] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [progressValue, setProgressValue] = useState(0)
  const [progressMaxValue, setProgressMaxValue] = useState(0)
  const [muted, setMuted] = useState(false)
  const [tooltipTouchActive, setTooltipTouchActive] = useState(false)
  const [controlsActive, setControlsActive] = useState(true)

  const textPlay = formatMessage({
    id: 'VideoPlayer_Play',
    defaultMessage: 'Play',
  })

  const textPause = formatMessage({
    id: 'VideoPlayer_Pause',
    defaultMessage: 'Pause',
  })

  const textMute = formatMessage({
    id: 'VideoPlayer_Mute',
    defaultMessage: 'Mute',
  })

  const textUnmute = formatMessage({
    id: 'VideoPlayer_Unmute',
    defaultMessage: 'Unmute',
  })

  const mouseEnterVideo = () => setControlsActive(true)
  const mouseLeaveVideo = () => setControlsActive(false)

  const progressFunc = () => {
    if (videoRef && videoRef.current) {
      setProgressValue(Math.floor(videoRef.current.currentTime))
    }
  }

  const initFunc = () => {
    if (videoRef && videoRef.current) {
      if (videoRef.current.readyState >= 1) {
        const videoDuration = Math.round(videoRef.current.duration)
        setProgressMaxValue(videoDuration)
      }
    }
  }

  const playVideo = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.play()
      setFirstRun(false)
      setPlaying(true)
    }
  }

  const togglePlay = () => {
    if (videoRef && videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) {
        setPlaying(true)
        videoRef.current.play()
      } else {
        setPlaying(false)
        videoRef.current.pause()
      }
    }
  }

  const toggleSound = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted
      setMuted(videoRef.current.muted)
    }
  }

  const skipAhead = () => {
    if (!(window as any).Modernizr.touchevents) {
      const videoRefCurrent = videoRef && videoRef.current
      const seekRefCurrent = seekRef && seekRef.current

      if (videoRefCurrent && seekRefCurrent) {
        const skipTo = seekRefCurrent.dataset.seek

        if (skipTo) {
          videoRefCurrent.currentTime = window.parseInt(skipTo)
          setProgressValue(window.parseInt(skipTo) || 0)
        }
      }
    }
  }

  const updateSeekTooltip = (event: MouseEvent) => {
    const videoRefCurrent = videoRef && videoRef.current
    const seekRefCurrent = seekRef && seekRef.current
    const seekTooltipRefCurrent = seekTooltipRef && seekTooltipRef.current

    if (videoRefCurrent && seekRefCurrent && seekTooltipRefCurrent) {
      const skipTo = Math.round(
        (event.nativeEvent.offsetX / seekRefCurrent.clientWidth) *
          window.parseInt(seekRefCurrent.max, 10)
      )

      if (skipTo >= 0) {
        const t = formatTime(skipTo)

        seekRefCurrent.dataset.seek = skipTo.toString()
        seekTooltipRefCurrent.textContent = `${t.minutes}:${t.seconds}`
        seekTooltipRefCurrent.style.left = `${event.nativeEvent.offsetX}px`
      }
    }
  }

  const inputTouchStart = (event: TouchEvent) => {
    const videoRefCurrent = videoRef && videoRef.current
    const seekRefCurrent = seekRef && seekRef.current
    const seekTooltipRefCurrent = seekTooltipRef && seekTooltipRef.current

    if (videoRefCurrent && seekRefCurrent && seekTooltipRefCurrent) {
      let pageX = -1
      const rect = seekRefCurrent.getBoundingClientRect()

      if (event.changedTouches && event.changedTouches[0]) {
        pageX = event.changedTouches[0].pageX
      }

      const skipTo = Math.round(
        ((pageX - rect.x) / seekRefCurrent.clientWidth) *
          window.parseInt(seekRefCurrent.max, 10)
      )

      if (skipTo >= 0 && pageX > 0) {
        const t = formatTime(skipTo)

        seekRefCurrent.dataset.seek = skipTo.toString()
        seekTooltipRefCurrent.textContent = `${t.minutes}:${t.seconds}`
        seekTooltipRefCurrent.style.left = `${pageX - rect.x}px`

        videoRefCurrent.currentTime = skipTo
        setProgressValue(skipTo)
      }
    }

    setTooltipTouchActive(true)
  }

  const inputTouchEnd = () => setTooltipTouchActive(false)

  const progressBarSpanStyle = {
    width: (progressValue / progressMaxValue) * 100 + '%',
  }

  const classesControls = classnames('VideoPlayer__controls', {
    'VideoPlayer__controls--active': controlsActive,
  })

  const classesTooltip = classnames(
    'VideoPlayer__controls__progress__seek__tooltip',
    {
      'VideoPlayer__controls__progress__seek__tooltip--active':
        tooltipTouchActive,
    }
  )

  return (
    <div
      className="VideoPlayer"
      onMouseEnter={mouseEnterVideo}
      onMouseLeave={mouseLeaveVideo}
    >
      <div className="VideoPlayer__inner">
        <div
          className="VideoPlayer__ratio"
          style={{ paddingTop: `${100 * ratio}%` }}
        />
        <video
          className="VideoPlayer__video"
          ref={videoRef}
          playsInline
          loop
          preload="metadata"
          onLoadedMetadata={initFunc}
          onTimeUpdate={progressFunc}
        >
          <source src={`${video.url}#t=0.1`} type="video/mp4" />
        </video>

        {/* {firstRun && (
          <button className="VideoPlayer__btn__play" onClick={playVideo}>
            <svg viewBox="0 0 60 60">
              <path d="M29.7,8.5C17.9,8.5,8.2,18.1,8.2,30s9.6,21.5,21.5,21.5c11.9,0,21.5-9.6,21.5-21.5S41.6,8.5,29.7,8.5z M24.2,38V22l16,8L24.2,38z" />
            </svg>
          </button>
        )} */}

        {/* {!firstRun && (
          <button
            className="VideoPlayer__btn__play__overlay"
            onClick={togglePlay}
          />
        )} */}

        <button
          className="VideoPlayer__btn__play__overlay"
          onClick={togglePlay}
        />

        <div className={classesControls}>
          <div className="VideoPlayer__controls__left">
            <button
              className="VideoPlayer__controls__pause__button"
              onClick={togglePlay}
              title={playing ? textPause : textPlay}
            >
              {playing ? textPause : textPlay}
            </button>
          </div>

          <div className="VideoPlayer__controls__center">
            <div className="VideoPlayer__controls__progress">
              <div className="VideoPlayer__controls__progress__bar">
                <div className="VideoPlayer__controls__progress__bar__bg" />
                <span style={progressBarSpanStyle} />
              </div>
              <input
                ref={seekRef}
                className="VideoPlayer__controls__progress__seek"
                value={progressValue}
                min="0"
                max={progressMaxValue}
                type="range"
                step="1"
                onMouseMove={updateSeekTooltip}
                onInput={skipAhead}
                onTouchStart={inputTouchStart}
                onTouchEnd={inputTouchEnd}
                readOnly
              />
              <div className={classesTooltip} ref={seekTooltipRef}>
                00:00
              </div>
            </div>
          </div>

          <div className="VideoPlayer__controls__right">
            <button
              className="VideoPlayer__controls__mute__button"
              onClick={toggleSound}
              title={muted ? textUnmute : textMute}
            >
              {muted ? textUnmute : textMute}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoPlayer
