import React, { ReactElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { VideoType } from 'data/types'
import { handleContentLinks } from 'utils/handleContentLinks'
import Video from 'view/components/video/Video'
import VideoPlayer from 'view/components/video-player/VideoPlayer'

import './BlockVideo.scss'

type Props = {
  locale: string
  autoplay: boolean
  paddingTop: string | null
  video: VideoType[]
  width: number
  height: number
  caption: string
}

const BlockVideo = ({
  locale,
  autoplay,
  paddingTop,
  video,
  width,
  height,
  caption,
}: Props): ReactElement | null => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const videoFirst = video.length > 0 && video[0]

  if (!videoFirst) {
    return null
  }

  const ratio = height / (width > 0 ? width : 1)
  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockVideo',
    {
      'BlockVideo--portrait': ratio > 1,
    }
  )

  const videoHtml = autoplay ? (
    <Video video={videoFirst} ratio={ratio} />
  ) : (
    <VideoPlayer video={videoFirst} ratio={ratio} />
  )

  return (
    <div className={classes}>
      {videoHtml}
      {caption && (
        <div
          className="BlockVideo__caption"
          dangerouslySetInnerHTML={{ __html: caption }}
          onClick={handleContentClick}
        />
      )}
    </div>
  )
}

export default BlockVideo
