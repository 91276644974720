import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'

import { PressType } from 'data/types'
import { motionPageVariants } from 'utils/motionVariants'
import Blocks from 'view/components/blocks/Blocks'

import './Press.scss'

const Press = ({ locale, projects, contents }: PressType): ReactElement => (
  <motion.section
    className="Press"
    variants={motionPageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <Blocks
      contents={contents}
      projects={projects}
      locale={locale}
      projectId={null}
      allowExpanders={true}
    />
  </motion.section>
)

export default Press
