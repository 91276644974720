import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import './BlockLinkList.scss'

const BlockLinkList = ({ paddingTop, links }): ReactElement => {
  const { formatMessage } = useIntl()

  const textTitle = formatMessage({
    id: 'BlockLinkList_Title',
    defaultMessage: 'Externe Links',
  })

  const items = links.map((item, index) => {
    const urlText = item.url.replace(/(^\w+:|^)\/\//, '').replace('www.', '')

    return (
      <div key={index} className="BlockLinkList__item">
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          {urlText} ↗
        </a>
      </div>
    )
  })

  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockLinkList'
  )

  return (
    <div className={classes}>
      <div className="BlockLinkList__title">{textTitle}</div>
      <div className="BlockLinkList__content">{items}</div>
    </div>
  )
}

export default BlockLinkList
