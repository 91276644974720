import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'

import { BASE_URL } from 'Constants'
import { motionPageVariants } from 'utils/motionVariants'
import Helmet from 'view/components/helmet/DefaultHelmet'
import ScrollToTop from 'view/components/scroll-to-top/ScrollToTop'

import './Privacy.scss'

const Privacy = ({ locale, title, contents }): ReactElement => {
  const blocks = contents.map((item) => {
    const { id, siteId, hidden, title, content } = item

    if (hidden) return null

    return (
      <div key={`${siteId}-${id}`} className="Privacy__contents__item">
        {title && <div className="Privacy__contents__item__title">{title}</div>}
        <div
          className="Privacy__contents__item__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    )
  })

  const helmetParams = {
    title: title,
    description: title,
    url:
      locale === 'de' ? `${BASE_URL}/de/datenschutz` : `${BASE_URL}/en/privacy`,
  }

  return (
    <>
      <Helmet {...helmetParams} />
      <ScrollToTop />
      <motion.section
        className="Privacy"
        variants={motionPageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <div className="Privacy__title">{title}</div>
        <div className="Privacy__contents">{blocks}</div>
      </motion.section>
    </>
  )
}

export default Privacy
