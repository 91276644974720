import { gql } from '@apollo/client'

import { COMMON_CONTENTS } from './fragments'

const IMPRINT_PAGE_QUERY = gql`
  ${COMMON_CONTENTS}
  query ImprintPageQuery($siteId: [QueryArgument]) {
    result: entry(section: "imprintPage", siteId: $siteId) {
      id
      siteId
      title
      ... on imprintPage_imprintPage_Entry {
        contents: commonContents {
          ...CommonContents
        }
      }
    }
  }
`

export default IMPRINT_PAGE_QUERY
