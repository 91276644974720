import { gql } from '@apollo/client'

export const COMMON_CONTENTS = gql`
  fragment CommonContents on commonContents_MatrixField {
    type: __typename
    ... on commonContents_assetGrid_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      layout: blockLayout
      assets: blockAssets {
        id
        url @transform(handle: "large")
        title
        width
        height
        kind
      }
      caption: blockCaption
    }
    ... on commonContents_audio_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      related: blockRelated {
        id
        siteId
        title
        ... on audio_audio_Entry {
          description: audioDescription
          duration: audioDuration
          tracks: audioTracks {
            ... on audioTracks_track_BlockType {
              id
              hidden: blockHidden
              title: blockTitle
              track: blockTrack {
                url
              }
            }
          }
        }
      }
    }
    ... on commonContents_comments_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
    }
    ... on commonContents_custom_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      title: blockTitle
      component: blockComponent
    }
    ... on commonContents_events_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
    }
    ... on commonContents_expander_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      title: blockTitle
      related: blockRelated {
        id
        siteId
        slug
        title
        ... on projects_project_Entry {
          expanderTitle: projectExpanderTitle
          expanderSubtitle: projectExpanderSubtitle
          thumb: projectThumb {
            id
            url @transform(handle: "mini")
            title
            width
            height
          }
        }
      }
    }
    ... on commonContents_image_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      layout: blockLayout
      images: blockImages {
        id
        url @transform(handle: "large")
        title
        width
        height
      }
      caption: blockCaption
    }
    ... on commonContents_index_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
    }
    ... on commonContents_linkList_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      links: blockLinks {
        ... on blockLinks_TableRow {
          url
        }
      }
    }
    ... on commonContents_meta_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      related: blockRelated {
        id
        siteId
        title
        ... on meta_meta_Entry {
          contents: metaContents {
            ... on metaContents_column_BlockType {
              id
              siteId
              hidden: blockHidden
              content: blockContent
            }
          }
        }
      }
    }
    ... on commonContents_press_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
    }
    ... on commonContents_quote_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      quote: blockQuote
      author: blockAuthor
    }
    ... on commonContents_relatedEvent_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      related: blockRelated {
        id
        siteId
        title
        ... on events_event_Entry {
          date: eventDate
            @formatDateTime(format: "d.m.Y", timezone: "Europe/Zurich")
          time: eventTime
          type: eventType
        }
      }
    }
    ... on commonContents_relatedProject_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      related: blockRelated {
        id
        siteId
        slug
        title
        ... on projects_project_Entry {
          thumb: projectThumb {
            id
            url @transform(handle: "mini")
            title
            width
            height
          }
        }
      }
    }
    ... on commonContents_relatedResearch_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      citationSource: blockCitationSource
      citationLink: blockCitationLink
      abstract: blockAbstract
      text: blockText
    }
    ... on commonContents_references_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      related: blockRelated {
        id
        siteId
        ... on references_reference_Entry {
          contents: referenceContents {
            ... on referenceContents_reference_BlockType {
              id
              siteId
              source: blockSource
              link: blockLink
            }
          }
        }
      }
    }
    ... on commonContents_slideshow_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      title: blockTitle
      images: blockImages {
        id
        url @transform(handle: "large")
        title
        width
        height
      }
      caption: blockCaption
    }
    ... on commonContents_text_BlockType {
      id
      siteId
      hidden: blockHidden
      paddingTop: blockPaddingTop
      title: blockTitle
      content: blockContent
      contentIndent: blockContentIndent
    }
    ... on commonContents_video_BlockType {
      id
      siteId
      hidden: blockHidden
      autoplay: blockAutoplay
      paddingTop: blockPaddingTop
      video: blockVideo {
        id
        url
      }
      width: blockVideoWidth
      height: blockVideoHeight
      caption: blockCaption
    }
  }
`
