import { useQuery } from '@apollo/client'
import React, { useEffect, useState, useRef, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import { motion, AnimatePresence } from 'framer-motion'
import classnames from 'classnames'

import PROJECT_EXPANDER_QUERY from 'data/queries/project-expander'
import { handleContentLinks } from 'utils/handleContentLinks'
import { motionExpanderItemDetailVariants } from 'utils/motionVariants'
import Blocks from 'view/components/blocks/Blocks'
import Image from 'view/components/image/Image'

import './BlockExpanderItem.scss'

const BlockExpanderItem = ({
  locale,
  projects,
  siteId,
  slug,
  title,
  expanderTitle,
  expanderSubtitle,
  thumb,
}): ReactElement => {
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (active && ref && ref.current) {
      animateScroll.scrollTo(
        window.pageYOffset + ref.current.getBoundingClientRect().top - 10,
        {
          duration: 300,
          delay: 250,
          smooth: true,
        }
      )
    }
  }, [active])

  const { data, loading } = useQuery(PROJECT_EXPANDER_QUERY, {
    variables: {
      slug,
      siteId,
    },
  })

  const toggleActive = () => setActive(!active)

  // 1. teaser
  const projectTitle = expanderTitle ? expanderTitle : title
  const thumbFirst = thumb && thumb.length > 0 && thumb[0]
  const classesGrid = classnames('BlockExpanderItem__teaser__grid', {
    'h-no-subtitle': !expanderSubtitle,
  })

  const teaserHtml = (
    <div className={classesGrid}>
      {expanderSubtitle && (
        <div className="BlockExpanderItem__teaser__subtitle">
          {expanderSubtitle}
        </div>
      )}
      <div className="BlockExpanderItem__teaser__title">
        <div className="BlockExpanderItem__teaser__title__icon">
          {active ? '–' : '+'}
        </div>

        <div className="BlockExpanderItem__teaser__title__text">
          {projectTitle}
        </div>

        {thumbFirst && (
          <Image
            image={thumbFirst}
            className="BlockExpanderItem__teaser__title__thumb"
          />
        )}

        {!thumbFirst && (
          <div className="BlockExpanderItem__teaser__title__thumb" />
        )}
      </div>
    </div>
  )

  // 2. detail content
  const textLoading = formatMessage({
    id: 'BlockExpanderItem_Loading',
    defaultMessage: 'Inhalte werden geladen…',
  })

  let detailHtml = null

  if (loading) {
    detailHtml = (
      <div className="BlockExpanderItem__detail__content">{textLoading}</div>
    )
  } else if (data && data.result) {
    const { id, abstract, contents } = data.result

    const handleContentClick = (ev) =>
      handleContentLinks(ev, locale, navigate, location)

    const blocks = (
      <Blocks
        contents={contents}
        projects={projects}
        locale={locale}
        projectId={id}
        allowExpanders={false}
      />
    )

    detailHtml = (
      <div className="BlockExpanderItem__detail__content">
        <div
          className="BlockExpanderItem__detail__content__abstract"
          dangerouslySetInnerHTML={{ __html: abstract }}
          onClick={handleContentClick}
        />
        {blocks}
      </div>
    )
  }

  return (
    <div className="BlockExpanderItem" ref={ref}>
      <div className="BlockExpanderItem__teaser" onClick={toggleActive}>
        {teaserHtml}
      </div>

      <AnimatePresence>
        {active && (
          <motion.div
            variants={motionExpanderItemDetailVariants}
            className="BlockExpanderItem__detail"
            initial="initial"
            animate="visible"
            exit="exit"
          >
            {detailHtml}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default BlockExpanderItem
