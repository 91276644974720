import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Image from 'view/components/image/Image'

import './BlockRelatedProject.scss'

const BlockRelatedProject = ({
  locale,
  paddingTop,
  related,
}): ReactElement | null => {
  const { formatMessage } = useIntl()

  const relatedFirst = related && related.length > 0 && related[0]

  if (relatedFirst) {
    const { slug, title, thumb } = relatedFirst
    const url = locale === 'de' ? `/de/projekt/${slug}` : `/en/project/${slug}`
    const thumbFirst = thumb && thumb.length > 0 && thumb[0]
    let thumbHtml = null

    const textHint = formatMessage({
      id: 'BlockRelatedProject_Hint',
      defaultMessage: 'mehr zum Thema im Artikel',
    })

    if (thumbFirst) {
      thumbHtml = (
        <Image
          image={thumbFirst}
          className="BlockRelatedProject__link__thumb"
        />
      )
    }

    const classes = classnames(
      'Block',
      paddingTop ? `h-padding-${paddingTop}` : '',
      'BlockRelatedProject'
    )

    return (
      <div className={classes}>
        <div className="BlockRelatedProject__grid">
          <div className="BlockRelatedProject__hint">{textHint}</div>
          <Link to={url} className="BlockRelatedProject__link">
            <div className="BlockRelatedProject__link__title">{title}</div>
            {thumbHtml}
          </Link>
        </div>
      </div>
    )
  }

  return null
}

export default BlockRelatedProject
