import { BASE_URL } from 'Constants'
import { pregQuote } from 'utils/regex'

export const handleContentLinks = (ev, locale, navigate, location) => {
  const targetLink = ev.target.closest('a')
  if (!targetLink) return

  ev.preventDefault()

  if (targetLink.classList.contains('h-link-tag')) {
    const found = targetLink.href.match(/\/tag\/(.*)/)

    if (found && found.length > 1) {
      // targetLink.classList.add('h-link-tag-active')

      navigate(`/${locale}/tag/${found[1]}`, {
        state: {
          backgroundLocation: location,
        },
      })

      return
    }
  } else if (targetLink.classList.contains('h-link-project')) {
    const found = targetLink.href.match(/\/(projekt|project)\/(.*)/)

    if (found && found.length > 2) {
      navigate(
        `/${locale}/${locale === 'de' ? 'projekt' : 'project'}/${found[2]}`
      )

      return
    }
  } else if (targetLink.classList.contains('h-link-internal')) {
    const regex = new RegExp(`${pregQuote(`${BASE_URL}`, '/')}\/(.*)`)
    const found = targetLink.href.match(regex)

    if (found && found.length > 1) {
      navigate(`/${found[1]}`)

      return
    }
  } else if (
    targetLink.target === '_blank' ||
    targetLink.classList.contains('h-link-external')
  ) {
    window.open(targetLink.href, '_blank')
    return
  }

  // fallback in case everything else fails
  window.open(targetLink.href)

  return
}
