import { gql } from '@apollo/client'

const INDEX_QUERY = gql`
  query IndexQuery($siteId: [QueryArgument]) {
    results: entries(section: "index", siteId: $siteId) {
      id
      siteId
      title
      ... on index_index_Entry {
        related: indexRelated {
          id
          slug
          ... on projects_project_Entry {
            thumb: projectThumb {
              id
              url @transform(handle: "mini")
              title
              width @transform(handle: "mini")
              height @transform(handle: "mini")
            }
          }
        }
        description: indexDescription
      }
    }
  }
`

export default INDEX_QUERY
