import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'

import { RootState } from 'data/redux/rootReducer'
import { motionLoadingVariants } from 'utils/motionVariants'

import './Loading.scss'

const mapStateToProps = (state: RootState) => ({
  showLoading: state.ui.showLoading,
})

const connector = connect(mapStateToProps)

type LoadingProps = ConnectedProps<typeof connector>

const Loading = ({ showLoading }: LoadingProps): ReactElement => (
  <AnimatePresence>
    {showLoading && (
      <motion.div
        className="Loading"
        variants={motionLoadingVariants}
        initial="initial"
        exit="exit"
      >
        <div className="Loading__bg" />
      </motion.div>
    )}
  </AnimatePresence>
)

export default connector(Loading)
