/* cookie banner animation */
export const motionCookieBannerVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { delay: 1.5 } },
}

/* events expander animation */
export const motionEventsItemDetailVariants = {
  initial: { height: 0, opacity: 0 },
  visible: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
}

/* content expander animation */
export const motionExpanderItemDetailVariants = {
  initial: { height: 0, opacity: 0 },
  visible: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
}

/* intro animations */
export const motionIntroVariants = {
  initial: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 1 } },
}

export const motionIntroContainerVariants = {
  show: { opacity: 1 },
  hidden: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

export const motionIntroItemVariants = {
  show: { opacity: 1, scale: 0 },
  hidden: (custom) => ({
    opacity: custom > 1 ? 0.5 : 0,
    scale: custom > 1 ? 10 : 20,
    transition: {
      duration: custom > 1 ? 2 : 10,
      ease: 'easeInOut',
      opacity: { duration: custom > 1 ? 4 : 6 },
    },
  }),
}

/* loading animations */
export const motionLoadingVariants = {
  initial: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.5 } },
}

/* navigation animations */
export const motionNavigationVariants = {
  hidden: { y: 100 },
  show: { y: 0, transition: { delay: 1 } },
}

/* not found animations */
export const motionNotFoundContainerVariants = {
  show: { opacity: 1 },
  hidden: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

export const motionNotFoundItemVariants = {
  show: { opacity: 1, scale: 0 },
  hidden: (custom) => ({
    opacity: custom > 1 ? 0.5 : 0,
    scale: custom > 1 ? 10 : 20,
    transition: {
      duration: custom > 1 ? 2 : 10,
      ease: 'easeInOut',
      opacity: { duration: custom > 1 ? 4 : 9 },
    },
  }),
}

/* page transitions */
export const motionPageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    position: 'absolute',
    opacity: 0,
    transition: {
      ease: [0.61, 1, 0.88, 1],
    },
  },
}

/* project index animations */
export const motionProjectIndexItemVariants = {
  initial: { opacity: 1 },
  inactive: { opacity: 0.5 },
}

export const motionProjectIndexItemImageVariants = {
  initial: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: 'spring', bounce: 0, duration: 0.6 },
  },
  exit: { opacity: 0, scale: 0 },
}

export const motionProjectIndexItemTitleVariants = {
  initial: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: 'spring', bounce: 0, duration: 0.6 },
  },
  exit: { opacity: 0, scale: 0 },
}
