import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import {
  uiChangeCurrentInvertedUrl,
  uiChangeLoading,
} from 'data/redux/ui/actions'
import PRESS_PAGE_QUERY from 'data/queries/press-page'
import { mapLangToSiteId } from 'utils/i18n'
import Helmet from 'view/components/helmet/DefaultHelmet'
import ScrollToTop from 'view/components/scroll-to-top/ScrollToTop'
import NotFound from 'view/content/not-found/NotFound'
import Press from './Press'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
})

const mapDispatchToProps = {
  actionChangeCurrentInvertedUrl: uiChangeCurrentInvertedUrl,
  actionChangeLoading: uiChangeLoading,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PressProviderProps = ConnectedProps<typeof connector>

const PressProvider = ({
  projects,
  actionChangeCurrentInvertedUrl,
  actionChangeLoading,
}: PressProviderProps): ReactElement => {
  const { formatMessage, locale } = useIntl()

  const { data, loading } = useQuery(PRESS_PAGE_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    actionChangeCurrentInvertedUrl(locale === 'de' ? '/en/press' : '/de/presse')
  }, [locale])

  useEffect(() => {
    actionChangeLoading(loading)
  }, [loading])

  if (loading) {
    return null
  }

  if (data && data.result) {
    const textPress = formatMessage({
      id: 'Navigation_Press',
      defaultMessage: 'Press',
    })

    const helmetParams = {
      title: textPress,
      description: textPress,
      url: locale === 'de' ? `${BASE_URL}de/presse` : `${BASE_URL}en/press`,
    }

    return (
      <>
        <Helmet {...helmetParams} />
        <ScrollToTop />
        <Press locale={locale} projects={projects} {...data.result} />
      </>
    )
  }

  return <NotFound />
}

export default connector(PressProvider)
