import React, { ReactElement, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import loadimages from 'loadimages'
import { motion } from 'framer-motion'

import { BASE_URL, IS_SERVER_SIDE } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import { contentShuffleProjects } from 'data/redux/content/actions'
import {
  uiChangeCurrentInvertedUrl,
  uiChangeLoading,
} from 'data/redux/ui/actions'
import { motionPageVariants } from 'utils/motionVariants'
import Helmet from 'view/components/helmet/DefaultHelmet'
import ScrollToTop from 'view/components/scroll-to-top/ScrollToTop'
import ProjectIndexItem from './ProjectIndexItem'

import './ProjectIndex.scss'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
})

const mapDispatchToProps = {
  actionChangeCurrentInvertedUrl: uiChangeCurrentInvertedUrl,
  actionChangeLoading: uiChangeLoading,
  actionShuffleProjects: contentShuffleProjects,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ProjectIndexProps = ConnectedProps<typeof connector>

const ProjectIndex = ({
  projects,
  actionChangeCurrentInvertedUrl,
  actionChangeLoading,
  actionShuffleProjects,
}: ProjectIndexProps): ReactElement => {
  const { formatMessage, locale } = useIntl()
  const [activeItem, setActiveItem] = useState(0)

  useEffect(() => {
    actionShuffleProjects()
    actionChangeLoading(true)

    loadimages(preloadImages, () => {
      actionChangeLoading(false)
    })
  }, [])

  useEffect(() => {
    actionChangeCurrentInvertedUrl(
      locale === 'de' ? '/en/projects' : '/de/projekte'
    )
  }, [locale])

  const preloadImages = []

  const items = projects.map((item) => {
    if (item.indexHidden) return null

    const thumbFirst = item.thumb && item.thumb.length > 0 && item.thumb[0]

    if (thumbFirst) {
      preloadImages.push(thumbFirst.largeUrl)
    }

    return (
      <ProjectIndexItem
        key={item.id}
        locale={locale}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        {...item}
      />
    )
  })

  if (!IS_SERVER_SIDE) {
  }

  const textProjects = formatMessage({
    id: 'Navigation_Projects',
    defaultMessage: 'Projekte',
  })

  const helmetParams = {
    title: textProjects,
    description: textProjects,
    url:
      locale === 'de' ? `${BASE_URL}/de/projekte` : `${BASE_URL}/en/projects`,
  }

  return (
    <>
      <Helmet {...helmetParams} />
      <ScrollToTop />
      <motion.section
        className="ProjectIndex"
        variants={motionPageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <div className="ProjectIndex__grid">{items}</div>
      </motion.section>
    </>
  )
}

export default connector(ProjectIndex)
