import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { NavLink, matchPath, useNavigate, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import { RootState } from 'data/redux/rootReducer'
import { contentDataLoad } from 'data/redux/content/actions'
import { motionNavigationVariants } from 'utils/motionVariants'

import './Navigation.scss'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
  currentInvertedUrl: state.ui.currentInvertedUrl,
  showIntro: state.ui.showIntro,
})

const mapDispatchToProps = {
  actionContentDataLoad: contentDataLoad,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type NavigationProps = ConnectedProps<typeof connector>

const Navigation = ({
  currentInvertedUrl,
  showIntro,
  projects,
  actionContentDataLoad,
}: NavigationProps): ReactElement => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { formatMessage, locale } = useIntl()

  const projectMatch = matchPath(
    locale === 'de' ? '/de/projekt/:slug' : '/en/project/:slug',
    pathname
  )
  const tagMatch = matchPath(
    locale === 'de' ? '/de/tag/:slug' : '/en/tag/:slug',
    pathname
  )

  const actionChangeLangButton = () => {
    const newLang = locale === 'de' ? 'en' : 'de'

    // change the language, so all components update to new language
    actionContentDataLoad(newLang)

    navigate(currentInvertedUrl)
  }

  let projectHtml = null

  if (projectMatch) {
    const project = projects.find(
      (item) => item.slug === projectMatch.params.slug
    )

    if (project) {
      projectHtml = <div className="Navigation__project">{project.title}</div>
    }
  }

  const textBack = formatMessage({
    id: 'Navigation_Back',
    defaultMessage: 'Zurück',
  })

  const textIntro = formatMessage({
    id: 'Navigation_Intro',
    defaultMessage: 'Einleitung',
  })

  const textProjects = formatMessage({
    id: 'Navigation_Projects',
    defaultMessage: 'Projekte',
  })

  const textPress = formatMessage({
    id: 'Navigation_Press',
    defaultMessage: 'Press',
  })

  const textExhibitions = formatMessage({
    id: 'Navigation_Exhibitions',
    defaultMessage: 'Exhibitions',
  })

  const textImprint = formatMessage({
    id: 'Navigation_Imprint',
    defaultMessage: 'Imprint',
  })

  const urlIntro = `/${locale}/`
  const urlProjects = locale === 'de' ? '/de/projekte' : '/en/projects'
  const urlPress = locale === 'de' ? '/de/presse' : '/en/press'
  const urlExhibitions = locale === 'de' ? '/de/ausstellung' : '/en/exhibition'
  const urlImprint = locale === 'de' ? '/de/impressum' : '/en/imprint'

  if (tagMatch) {
    return null
  }

  return (
    <motion.div
      className="Navigation"
      variants={motionNavigationVariants}
      initial="hidden"
      animate={showIntro ? 'hidden' : 'show'}
    >
      <div className="Navigation__grid">
        <div className="Navigation__center">
          <NavLink
            to={urlIntro}
            className={({ isActive }) =>
              `Navigation__link${isActive ? ' Navigation__link--active' : ''}`
            }
          >
            <span className="Navigation__link__text">{textIntro}</span>
          </NavLink>

          <NavLink
            to={urlProjects}
            className={({ isActive }) =>
              `Navigation__link${
                isActive || projectMatch ? ' Navigation__link--active' : ''
              }`
            }
          >
            <span className="Navigation__link__text">{textProjects}</span>
          </NavLink>

          {projectHtml}

          <NavLink
            to={urlPress}
            className={({ isActive }) =>
              `Navigation__link${isActive ? ' Navigation__link--active' : ''}`
            }
          >
            <span className="Navigation__link__text">{textPress}</span>
          </NavLink>

          <NavLink
            to={urlExhibitions}
            className={({ isActive }) =>
              `Navigation__link${isActive ? ' Navigation__link--active' : ''}`
            }
          >
            <span className="Navigation__link__text">{textExhibitions}</span>
          </NavLink>

          <NavLink
            to={urlImprint}
            className={({ isActive }) =>
              `Navigation__link${isActive ? ' Navigation__link--active' : ''}`
            }
          >
            <span className="Navigation__link__text">{textImprint}</span>
          </NavLink>
        </div>

        <div className="Navigation__right">
          <button
            className="Navigation__btn Navigation__btn--lang"
            onClick={actionChangeLangButton}
          >
            <span className="Navigation__btn__text">
              {locale === 'de' ? 'EN' : 'DE'}
            </span>
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default connector(Navigation)
