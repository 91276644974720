import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import {
  uiChangeCurrentInvertedUrl,
  uiChangeLoading,
} from 'data/redux/ui/actions'
import EXHIBITIONS_PAGE_QUERY from 'data/queries/exhibitions-page'
import { mapLangToSiteId } from 'utils/i18n'
import Helmet from 'view/components/helmet/DefaultHelmet'
import ScrollToTop from 'view/components/scroll-to-top/ScrollToTop'
import NotFound from 'view/content/not-found/NotFound'
import Exhibitions from './Exhibitions'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
})

const mapDispatchToProps = {
  actionChangeCurrentInvertedUrl: uiChangeCurrentInvertedUrl,
  actionChangeLoading: uiChangeLoading,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ExhbitionsProviderProps = ConnectedProps<typeof connector>

const ExhbitionsProvider = ({
  projects,
  actionChangeCurrentInvertedUrl,
  actionChangeLoading,
}: ExhbitionsProviderProps): ReactElement => {
  const { formatMessage, locale } = useIntl()

  const { data, loading } = useQuery(EXHIBITIONS_PAGE_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    actionChangeCurrentInvertedUrl(
      locale === 'de' ? '/en/exhibition' : '/de/ausstellung'
    )
  }, [locale])

  useEffect(() => {
    actionChangeLoading(loading)
  }, [loading])

  if (loading) {
    return null
  }

  if (data && data.result) {
    const textExhibitions = formatMessage({
      id: 'Navigation_Exhibitions',
      defaultMessage: 'Exhibitions',
    })

    const helmetParams = {
      title: textExhibitions,
      description: textExhibitions,
      url:
        locale === 'de'
          ? `${BASE_URL}de/ausstellung`
          : `${BASE_URL}en/exhibition`,
    }

    return (
      <>
        <Helmet {...helmetParams} />
        <ScrollToTop />
        <Exhibitions locale={locale} projects={projects} {...data.result} />
      </>
    )
  }

  return <NotFound />
}

export default connector(ExhbitionsProvider)
