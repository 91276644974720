import React, { ReactElement, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import classnames from 'classnames'
import fetch from 'isomorphic-unfetch'
import moment from 'moment'

import { BACKEND_URL, BREAKPOINT_S } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import { CommentsFormInputsType, CommentsFormSentMessageType } from 'data/types'
import { useWindowSize } from 'utils/useWindowSize'

import './BlockComments.scss'

const mapStateToProps = (state: RootState) => ({
  comments: state.content.comments,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface BlockCommentsProps extends PropsFromRedux {
  locale: string
  paddingTop: string
  projectId: string | null
}

const BlockComments = ({
  locale,
  paddingTop,
  projectId,
  comments,
}: BlockCommentsProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const [focusFields, setFocusFields] = useState({
    name: false,
    comment: false,
  })
  const [formSentMessage, setFormSentMessage] =
    useState<CommentsFormSentMessageType>({})
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CommentsFormInputsType>()
  const { formatMessage } = useIntl()
  const windowSize = useWindowSize()
  let totalComments = 0

  const commentsHtml = comments.map((item) => {
    const { id, name, comment, date, related } = item
    const relatedFirst = related && related.length > 0 && related[0]

    const commentToBr = comment.split('\n').map((part, key) => (
      <span key={key}>
        {part}
        <br />
      </span>
    ))

    const dateDayMonth = moment(date, 'DD.MM.YYYY HH::mm')
      .locale(locale)
      .format('DD. MMMM YYYY HH:mm')

    if (relatedFirst && relatedFirst.id === projectId) {
      totalComments++

      return (
        <div key={id} className="BlockComments__comments__item">
          <div className="BlockComments__comments__item__meta">
            <span className="BlockComments__comments__item__meta__name">
              {name}
            </span>
            <span className="BlockComments__comments__item__meta__date">
              &nbsp;{dateDayMonth}
            </span>
          </div>
          <div className="BlockComments__comments__item__content">
            {commentToBr}
          </div>
        </div>
      )
    }

    return null
  })

  const focusHandler = (field: string) => () => {
    setFocusFields({
      ...focusFields,
      [field]: true,
    })
  }

  const blurHandler = (field: string) => () => {
    setFocusFields({
      ...focusFields,
      [field]: false,
    })
  }

  const calcTextAreaHeight = (value) => {
    const lineHeight = windowSize.width <= BREAKPOINT_S ? 28 : 40
    const numberOfLineBreaks = (value.match(/\n/g) || []).length
    // min-height + lines x line-height
    return lineHeight + numberOfLineBreaks * lineHeight
  }

  const handleKeyUp = (event: React.KeyboardEvent) => {
    const textArea = event.target as HTMLInputElement
    textArea.style.height = calcTextAreaHeight(textArea.value) + 'px'
  }

  const onSubmit = (dataFromForm: CommentsFormInputsType) => {
    if (BACKEND_URL) {
      // convert to form-data, because that is what Craft needs
      const formData = new FormData()
      Object.keys(dataFromForm).forEach((key) =>
        formData.append(key, dataFromForm[key])
      )

      // add action query, so Craft knows where to send request
      formData.append('action', 'planetdigital/frontend/submit')

      // indicate that we are processing the form on the backend
      setFormSentMessage({ processing: true })

      // do fetch POST request
      fetch(BACKEND_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setFormSentMessage(data)
        })
        .catch((error) => console.error(error))
    }
  }

  const textFormTitle = formatMessage({
    id: 'BlockComments_Form_Title',
    defaultMessage: 'Diskutieren Sie mit:',
  })

  const textFormName = formatMessage({
    id: 'BlockComments_Form_Name',
    defaultMessage: 'Name',
  })

  const textFormComment = formatMessage({
    id: 'BlockComments_Form_Comment',
    defaultMessage: 'Teilen Sie Ihre Meinung…',
  })

  const textFormInputRequired = formatMessage({
    id: 'BlockComments_Form_Input_Required',
    defaultMessage: 'Bitte füllen Sie dieses Feld aus.',
  })

  const textFormSubmit = formatMessage({
    id: 'BlockComments_Form_Submit',
    defaultMessage: 'Beitragen',
  })

  const textFormProcessing = formatMessage({
    id: 'BlockComments_Form_Processing',
    defaultMessage:
      '<strong>Das Formular wird gerade versendet.</strong> Bitte haben Sie einen Moment Geduld.',
  })

  const textFormSuccessThanks = formatMessage({
    id: 'BlockComments_Form_Success_Thanks',
    defaultMessage:
      '<strong>Vielen Dank für Ihren Kommentar!</strong> Wir werden ihn prüfen und anschliessend hochladen.',
  })

  const textCommentsTitle = formatMessage(
    {
      id: 'BlockComments_Comments_Title',
      defaultMessage:
        '{itemCount, plural, one {Ein Kommentar} other {{itemCount} Kommentare}}',
    },
    {
      itemCount: totalComments,
    }
  )

  const requiredHint = (
    <div className="BlockComments__form__error">{textFormInputRequired}</div>
  )

  const processingHint = (
    <div
      className="BlockComments__form__processing"
      dangerouslySetInnerHTML={{ __html: textFormProcessing }}
    />
  )

  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockComments'
  )

  const formHtml = (disabled: boolean) => (
    <form
      className={`BlockComments__form__container${
        disabled ? ' h-disabled' : ''
      }`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="BlockComments__form__left">
        <input
          {...register('name', { required: true })}
          className="BlockComments__form__name"
          onFocus={focusHandler('name')}
          onBlur={blurHandler('name')}
          placeholder={focusFields.name ? '' : textFormName}
          disabled={disabled}
        />
        {errors.name && requiredHint}
      </div>

      <div className="BlockComments__form__right">
        {/* fake field to catch bots and spammers */}
        <div className="BlockComments__form__handle">
          <input
            {...register('handle')}
            type="text"
            id="handle"
            aria-hidden="true"
            tabIndex={-1}
          />
        </div>
        {/* locale field, so the backend returns in correct language */}
        <input type="hidden" value={locale} {...register('locale')} />

        {/* related project field, so comment gets attached to correct project */}
        <input
          {...register('relatedProject')}
          type="hidden"
          id="relatedProject"
          value={projectId}
        />

        <textarea
          {...register('comment', { required: true })}
          className="BlockComments__form__comment"
          rows={1}
          cols={20}
          onFocus={focusHandler('comment')}
          onBlur={blurHandler('comment')}
          onKeyUp={handleKeyUp}
          placeholder={focusFields.comment ? '' : textFormComment}
          disabled={disabled}
        />
        {errors.comment && requiredHint}

        <input
          type="submit"
          className="BlockComments__form__submit"
          value={textFormSubmit}
          disabled={disabled}
        />
      </div>
    </form>
  )

  let formBlock = (
    <div className="BlockComments__form">
      <div className="BlockComments__form__title">{textFormTitle}</div>
      {formHtml(false)}
    </div>
  )

  if (formSentMessage.success) {
    const dateDayMonth = moment(
      formSentMessage.success.date,
      'DD.MM.YYYY HH:mm'
    )
      .locale(locale)
      .format('DD. MMMM YYYY HH:mm')

    const commentToBr = formSentMessage.success.comment
      .split('\n')
      .map((part, key) => (
        <span key={key}>
          {part}
          <br />
        </span>
      ))

    formBlock = (
      <div className="BlockComments__form">
        <div className="BlockComments__form__title">{textFormTitle}</div>
        <div className="BlockComments__form__success">
          <div className="BlockComments__form__success__preview">
            <div className="BlockComments__form__success__preview__meta">
              <span className="BlockComments__form__success__preview__meta__name">
                {formSentMessage.success.name}
              </span>
              <span className="BlockComments__form__success__preview__meta__date">
                &nbsp;{dateDayMonth}
              </span>
            </div>
            <div className="BlockComments__form__success__preview__content">
              {commentToBr}
            </div>
          </div>
          <div
            className="BlockComments__form__success__hint"
            dangerouslySetInnerHTML={{ __html: textFormSuccessThanks }}
          />
        </div>
      </div>
    )
  } else if (formSentMessage.errors) {
    const errorHints = Object.values(formSentMessage.errors).map(
      (item: any, index) =>
        item.length > 0 && (
          <div key={index} className="BlockComments__form__error__item">
            {item[0]}
          </div>
        )
    )

    formBlock = (
      <>
        <div className="BlockComments__form">
          <div className="BlockComments__form__title">{textFormTitle}</div>
          {formHtml(false)}
          {errorHints && (
            <div className="BlockComments__form__errors">{errorHints}</div>
          )}
        </div>
      </>
    )
  } else if (formSentMessage.processing) {
    formBlock = (
      <div className="BlockComments__form">
        <div className="BlockComments__form__title">{textFormTitle}</div>
        {formHtml(true)}
        {processingHint}
      </div>
    )
  }

  return (
    <div className={classes} ref={ref}>
      {formBlock}

      {totalComments > 0 && (
        <div className="BlockComments__comments">
          <div className="BlockComments__comments__title">
            {textCommentsTitle}
          </div>
          <div className="BlockComments__comments__items">{commentsHtml}</div>
        </div>
      )}
    </div>
  )
}

export default connector(BlockComments)
