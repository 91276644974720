import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import moment from 'moment'
import striptags from 'striptags'
import trim from 'trim'

const TagEvent = ({ locale, id, title, date, time, type }): ReactElement => {
  const timeClean = trim(
    striptags(time, ['br', 'em', 'i', 'strong', 'b', 'sup', 'sub', 'a'])
  )

  let dateSpan = null

  if (date) {
    const dateDayOfWeekName = moment(date, 'DD.MM.YYYY')
      .locale(locale)
      .format('dddd')
    const dateDayMonth = moment(date, 'DD.MM.YYYY')
      .locale(locale)
      .format('DD. MMMM')

    dateSpan = (
      <>
        <span>{dateDayOfWeekName}, </span>
        <span>
          <strong>{dateDayMonth}</strong>
        </span>
        {time && (
          <span
            className="Events__item__teaser__date__time"
            dangerouslySetInnerHTML={{ __html: ', ' + timeClean }}
          />
        )}
      </>
    )
  }

  return (
    <div className="Tag__event">
      <Link
        className="Tag__event__link"
        to={`${
          locale === 'en' ? `/en/events?id=${id}` : `/de/events?id=${id}`
        }`}
      >
        <div className="Tag__event__date">{dateSpan}</div>
        <div className="Tag__event__title">{title}</div>
        <div className="Tag__event__meta">
          <div
            className="Tag__event__meta__type"
            dangerouslySetInnerHTML={{ __html: type }}
          />
        </div>
      </Link>
    </div>
  )
}

const TagEvents = ({ locale, events }): ReactElement | null => {
  const { formatMessage } = useIntl()

  const textEventsTitle = formatMessage({
    id: 'Tag_Events_Title',
    defaultMessage: 'Veranstaltungen',
  })

  if (events && events.length > 0) {
    const items = events.map((item) => (
      <TagEvent key={item.id} locale={locale} {...item} />
    ))

    return (
      <div className="Tag__section">
        <div className="Tag__section__title">{textEventsTitle}</div>
        <div className="Tag__section__content h-type-events">{items}</div>
      </div>
    )
  }

  return null
}

export default TagEvents
