import React, { ReactElement, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  uiChangeCurrentInvertedUrl,
  uiChangeIntro,
} from 'data/redux/ui/actions'
import Helmet from 'view/components/helmet/DefaultHelmet'
import ScrollToTop from 'view/components/scroll-to-top/ScrollToTop'
import NotFoundAnimation from './NotFoundAnimation'

import './NotFound.scss'

const mapDispatchToProps = {
  actionChangeCurrentInvertedUrl: uiChangeCurrentInvertedUrl,
  actionChangeIntro: uiChangeIntro,
}

const connector = connect(null, mapDispatchToProps)

type NotFoundProps = ConnectedProps<typeof connector>

let timeout = null

const NotFound = ({
  actionChangeCurrentInvertedUrl,
  actionChangeIntro,
}: NotFoundProps): ReactElement => {
  const [speed, setSpeed] = useState(1)
  const { formatMessage, locale } = useIntl()

  // disable intro on not found page
  useEffect(() => {
    actionChangeIntro(false)
    document.body.classList.add('h-not-found')

    return () => document.body.classList.remove('h-not-found')
  }, [])

  useEffect(() => {
    actionChangeCurrentInvertedUrl(locale === 'de' ? '/en' : '/de')
  }, [locale])

  const handleWheel = () => {
    setSpeed(10)
    clearTimeout(timeout)
    timeout = setTimeout(() => setSpeed(1), 500)
  }

  const textInfo = formatMessage({
    id: 'NotFound_Info',
    defaultMessage: 'Diese Seite existiert leider nicht',
  })

  const textBackHome = formatMessage({
    id: 'NotFound_Home',
    defaultMessage: 'zurück zur Startseite',
  })

  const helmetParams = {
    title: '404',
    description: textInfo,
  }

  return (
    <>
      <Helmet {...helmetParams} />
      <ScrollToTop />
      <section
        className="NotFound"
        onWheel={handleWheel}
        onTouchMove={handleWheel}
      >
        <NotFoundAnimation speed={speed} />
        <div className="NotFound__text">
          {textInfo}
          <br />
          <Link to={`/${locale}`}>{textBackHome}</Link>
        </div>
      </section>
    </>
  )
}

export default connector(NotFound)
