import { useQuery } from '@apollo/client'
import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

import TAG_QUERY from 'data/queries/tag'
import { mapLangToSiteId } from 'utils/i18n'
import Tag from './Tag'

const TagProvider = (): ReactElement | null => {
  const { slug } = useParams()
  const { locale } = useIntl()

  const { data, loading } = useQuery(TAG_QUERY, {
    variables: {
      slug,
      siteId: mapLangToSiteId(locale),
    },
  })

  if (loading) {
    return null
  }

  if (data && data.result) {
    return <Tag locale={locale} tags={data.tags} {...data.result} />
  }

  return null
}

export default TagProvider
