import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import striptags from 'striptags'
import trim from 'trim'

import { handleContentLinks } from 'utils/handleContentLinks'

import './BlockQuote.scss'

const BlockQuote = ({ locale, paddingTop, quote, author }): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const { formatMessage } = useIntl()

  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const quoteClean = trim(
    striptags(quote, ['br', 'em', 'i', 'strong', 'b', 'sup', 'sub', 'a'])
  )

  const textQuoteStart = formatMessage({
    id: 'BlockQuote_Quote_Start',
    defaultMessage: '«',
  })

  const textQuoteEnd = formatMessage({
    id: 'BlockQuote_Quote_End',
    defaultMessage: '»',
  })

  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockQuote'
  )

  return (
    <div className={classes}>
      <div className="BlockQuote__quote">
        {textQuoteStart}
        <span
          dangerouslySetInnerHTML={{ __html: quoteClean }}
          onClick={handleContentClick}
        />
        {textQuoteEnd}
      </div>
      <div
        className="BlockQuote__author"
        dangerouslySetInnerHTML={{ __html: author }}
      />
    </div>
  )
}

export default BlockQuote
