import { AnyAction } from 'redux'

import { BASE_URL } from 'Constants'
import { GlobalStateType } from 'data/types'
import { GLOBAL_DATA_LOAD } from './types'

const initialState: GlobalStateType = {
  title: '',
  description: '',
  image: [],
  url: null,
  googleAnalyticsId: '',
}

export default function (
  state = initialState,
  action: AnyAction
): GlobalStateType {
  switch (action.type) {
    case GLOBAL_DATA_LOAD: {
      const { data } = action.payload

      if (data) {
        const { title, description, image, googleAnalyticsId } = data

        return {
          ...state,
          title: title || state.title,
          description: description || state.description,
          image: image || state.image,
          googleAnalyticsId: googleAnalyticsId || state.googleAnalyticsId,
        }
      }

      return state
    }

    default:
      return state
  }
}
