import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import './BlockReferences.scss'

const BlockReferences = ({ paddingTop, related }) => {
  const { formatMessage } = useIntl()

  const textTitle = formatMessage({
    id: 'BlockReferences_Title',
    defaultMessage: 'Verwandte Forschungsarbeiten',
  })

  const relatedFirst = related && related.length > 0 && related[0]

  if (relatedFirst) {
    const blocks = relatedFirst.contents.map((item) => {
      const { id, hidden, source, link } = item
      if (hidden) return null

      const linkText = link
        ? link.replace(/(^\w+:|^)\/\//, '').replace('www.', '')
        : null

      return (
        <div key={id} className="BlockReferences__contents__item">
          {source && (
            <div
              className="BlockReferences__contents__item__source"
              dangerouslySetInnerHTML={{ __html: source }}
            />
          )}
          {linkText && (
            <div className="BlockReferences__contents__item__link">
              <a href={link} target="_blank" rel="noopener noreferrer">
                {linkText} ↗
              </a>
            </div>
          )}
        </div>
      )
    })

    const classes = classnames(
      'Block',
      paddingTop ? `h-padding-${paddingTop}` : '',
      'BlockReferences'
    )

    return (
      <div className={classes}>
        <div className="BlockReferences__title">{textTitle}</div>
        <div className="BlockReferences__contents">{blocks}</div>
      </div>
    )
  }

  return null
}

export default BlockReferences
