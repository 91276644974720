import { gql } from '@apollo/client'

const TAG_QUERY = gql`
  query TagQuery($slug: [String], $siteId: [QueryArgument]) {
    result: entry(section: "tags", slug: $slug, siteId: $siteId) {
      id
      siteId
      slug
      title
      ... on tags_tag_Entry {
        topic: tagTopic
        relatedProjects: tagRelatedProjects {
          id
          siteId
          slug
          title
        }
        relatedEvents: tagRelatedEvents {
          id
          siteId
          title
          ... on events_event_Entry {
            date: eventDate
              @formatDateTime(format: "d.m.Y", timezone: "Europe/Zurich")
            time: eventTime
            type: eventType
          }
        }
        excerpts: tagTextExcerpts {
          ... on tagTextExcerpts_excerpt_BlockType {
            id
            siteId
            hidden: blockHidden
            text: blockText
            related: blockRelated {
              id
              siteId
              slug
              title
            }
          }
        }
      }
    }
    tags: entries(section: "tags", siteId: $siteId) {
      id
      siteId
      slug
      title
    }
  }
`

export default TAG_QUERY
