import React, { ReactElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { BlockImageType } from 'data/types'
import { handleContentLinks } from 'utils/handleContentLinks'
import Image from 'view/components/image/Image'

import './BlockImage.scss'

const BlockImage = ({
  locale,
  paddingTop,
  layout,
  images,
  caption,
}: BlockImageType): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const layoutClass = images.length < 2 ? `BlockImage--${layout}` : ''

  const figures = images.map((image) => (
    <figure key={image.id} className="BlockImage__figure">
      <div
        className="BlockImage__ratio"
        style={{
          paddingTop: `${(image.height / image.width) * 100}%`,
        }}
      />
      <Image image={image} className="BlockImage__img" />
    </figure>
  ))

  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockImage',
    layoutClass
  )

  return (
    <div className={classes}>
      {figures && <div className="BlockImage__figures">{figures}</div>}
      {caption && (
        <div
          className="BlockImage__caption"
          dangerouslySetInnerHTML={{ __html: caption }}
          onClick={handleContentClick}
        />
      )}
    </div>
  )
}

export default BlockImage
