import React, {
  MouseEvent,
  TouchEvent,
  ReactElement,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { formatTime } from 'utils/formatTime'

import './BlockAudioTrack.scss'

const BlockAudioTrack = ({ index, title, track }): ReactElement => {
  const { formatMessage } = useIntl()

  const audioRef = useRef<HTMLAudioElement>(null)
  const seekRef = useRef<HTMLInputElement>(null)
  const seekTooltipRef = useRef<HTMLDivElement>(null)

  const [playing, setPlaying] = useState(false)
  const [progressValue, setProgressValue] = useState(0)
  const [progressMaxValue, setProgressMaxValue] = useState(0)
  const [totalDuration, setTotalDuration] = useState('')
  const [tooltipTouchActive, setTooltipTouchActive] = useState(false)

  const textPlay = formatMessage({
    id: 'BlockAudioTrack_Play',
    defaultMessage: 'Play',
  })

  const textPause = formatMessage({
    id: 'BlockAudioTrack_Pause',
    defaultMessage: 'Pause',
  })

  const initFunc = () => {
    if (audioRef && audioRef.current) {
      if (audioRef.current.readyState >= 1) {
        const audioDuration = Math.round(audioRef.current.duration)
        const t = formatTime(audioDuration)

        setTotalDuration(`${t.minutes}:${t.seconds}`)
        setProgressMaxValue(audioDuration)
      }
    }
  }

  const progressFunc = () => {
    if (audioRef && audioRef.current) {
      setProgressValue(Math.floor(audioRef.current.currentTime))
    }
  }

  const togglePlay = () => {
    if (audioRef && audioRef.current) {
      if (audioRef.current.paused || audioRef.current.ended) {
        setPlaying(true)
        audioRef.current.play()
      } else {
        setPlaying(false)
        audioRef.current.pause()
      }
    }
  }

  const skipAhead = () => {
    if (!(window as any).Modernizr.touchevents) {
      const audioRefCurrent = audioRef && audioRef.current
      const seekRefCurrent = seekRef && seekRef.current

      if (audioRefCurrent && seekRefCurrent) {
        const skipTo = seekRefCurrent.dataset.seek

        if (skipTo) {
          audioRefCurrent.currentTime = window.parseInt(skipTo)
          setProgressValue(window.parseInt(skipTo) || 0)
        }
      }
    }
  }

  const updateSeekTooltip = (event: MouseEvent) => {
    const audioRefCurrent = audioRef && audioRef.current
    const seekRefCurrent = seekRef && seekRef.current
    const seekTooltipRefCurrent = seekTooltipRef && seekTooltipRef.current

    if (audioRefCurrent && seekRefCurrent && seekTooltipRefCurrent) {
      const skipTo = Math.round(
        (event.nativeEvent.offsetX / seekRefCurrent.clientWidth) *
          window.parseInt(seekRefCurrent.max, 10)
      )

      if (skipTo >= 0) {
        const t = formatTime(skipTo)

        seekRefCurrent.dataset.seek = skipTo.toString()
        seekTooltipRefCurrent.textContent = `${t.minutes}:${t.seconds}`
        seekTooltipRefCurrent.style.left = `${event.nativeEvent.offsetX}px`
      }
    }
  }

  const inputTouchStart = (event: TouchEvent) => {
    const audioRefCurrent = audioRef && audioRef.current
    const seekRefCurrent = seekRef && seekRef.current
    const seekTooltipRefCurrent = seekTooltipRef && seekTooltipRef.current

    if (audioRefCurrent && seekRefCurrent && seekTooltipRefCurrent) {
      let pageX = -1
      const rect = seekRefCurrent.getBoundingClientRect()

      if (event.changedTouches && event.changedTouches[0]) {
        pageX = event.changedTouches[0].pageX
      }

      const skipTo = Math.round(
        ((pageX - rect.x) / seekRefCurrent.clientWidth) *
          window.parseInt(seekRefCurrent.max, 10)
      )

      if (skipTo >= 0 && pageX > 0) {
        const t = formatTime(skipTo)

        seekRefCurrent.dataset.seek = skipTo.toString()
        seekTooltipRefCurrent.textContent = `${t.minutes}:${t.seconds}`
        seekTooltipRefCurrent.style.left = `${pageX - rect.x}px`

        audioRefCurrent.currentTime = skipTo
        setProgressValue(skipTo)
      }
    }

    setTooltipTouchActive(true)
  }

  const inputTouchEnd = () => setTooltipTouchActive(false)

  const progressBarSpanStyle = {
    width: (progressValue / progressMaxValue) * 100 + '%',
  }

  const classesTooltip = classnames(
    'BlockAudioTrack__controls__progress__seek__tooltip',
    {
      'BlockAudioTrack__controls__progress__seek__tooltip--active':
        tooltipTouchActive,
    }
  )

  return (
    <div className="BlockAudioTrack">
      <div className="BlockAudioTrack__count">{index + 1}.</div>
      <div className="BlockAudioTrack__meta">
        <div className="BlockAudioTrack__meta__title">{title}</div>
        {totalDuration && (
          <div className="BlockAudioTrack__meta__duration">
            &nbsp;({totalDuration})
          </div>
        )}
      </div>
      <audio
        className="BlockAudioTrack__audio"
        ref={audioRef}
        preload="metadata"
        onLoadedMetadata={initFunc}
        onTimeUpdate={progressFunc}
      >
        <source src={track.url} type="audio/mpeg" />
      </audio>
      <div className="BlockAudioTrack__controls">
        <div className="BlockAudioTrack__controls__left">
          <button
            className="BlockAudioTrack__controls__pause__button"
            onClick={togglePlay}
            title={playing ? textPause : textPlay}
          >
            {playing ? textPause : textPlay}
          </button>
        </div>
        <div className="BlockAudioTrack__controls__center">
          <div className="BlockAudioTrack__controls__progress">
            <div className="BlockAudioTrack__controls__progress__bar">
              <div className="BlockAudioTrack__controls__progress__bar__bg" />
              <span style={progressBarSpanStyle} />
            </div>
            <input
              ref={seekRef}
              className="BlockAudioTrack__controls__progress__seek"
              value={progressValue}
              min="0"
              max={progressMaxValue}
              type="range"
              step="1"
              onMouseMove={updateSeekTooltip}
              onInput={skipAhead}
              onTouchStart={inputTouchStart}
              onTouchEnd={inputTouchEnd}
              readOnly
            />
            <div className={classesTooltip} ref={seekTooltipRef}>
              00:00
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockAudioTrack
