import React from 'react'
import { hydrate, render } from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { IS_DEV, BUILD_NAME, BUILD_VERSION } from 'Constants'
import createClient from 'data/graphql/createClient'
import configureStore from 'data/redux/configureStore'
import AppWithIntlProvider from './AppWithIntlProvider'

console.log(
  `%c${BUILD_NAME}\n%c${IS_DEV ? 'Dev' : 'Prod'} Build ${BUILD_VERSION}`,
  'color: #0046ff; font-size: 14px; font-family: monospace; line-height: 1em;',
  'color: grey; font-size: 10px; font-family: monospace, serif; line-height: 1em;'
)

// get token from Craft CMS preview if available
const tokenMatch = window.document.location.href.match(/\btoken=([^&]+)/)
const token = tokenMatch ? tokenMatch[1] : ''

const client = createClient(token)
const store = configureStore((window as any).__PRELOADED_STATE__, client)

const renderMethod = module.hot && IS_DEV ? render : hydrate

renderMethod(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <AppWithIntlProvider />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
)

if (module.hot && IS_DEV) {
  module.hot.accept()
}
