import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import truncate from 'truncate'

import {
  motionProjectIndexItemImageVariants,
  motionProjectIndexItemTitleVariants,
  motionProjectIndexItemVariants,
} from 'utils/motionVariants'
import Image from 'view/components/image/Image'

const ProjectIndexItem = ({
  locale,
  activeItem,
  setActiveItem,
  id,
  slug,
  title,
  meta,
  thumb,
  abstract,
}): ReactElement => {
  const url = locale === 'de' ? `/de/projekt/${slug}` : `/en/project/${slug}`
  const thumbFirst = thumb && thumb.length > 0 && thumb[0]
  const abstractShort = truncate(abstract, 200, { ellipsis: '…' })
  let timeout = null

  const handleMouseEnter = () => {
    if (!(window as any).Modernizr.touchevents) {
      timeout = window.setTimeout(() => setActiveItem(id), 500)
    }
  }

  const handleMouseLeave = () => {
    if (!(window as any).Modernizr.touchevents) {
      window.clearTimeout(timeout)
      setActiveItem(0)
    }
  }

  const activeCurrent = activeItem === id
  const animate =
    activeItem !== 0 ? (activeCurrent ? 'active' : 'inactive') : 'initial'

  const metaClean = meta === '<p><strong><br /></strong></p>' ? null : meta

  return (
    <>
      <AnimatePresence>
        {activeCurrent && (
          <>
            <motion.div
              variants={motionProjectIndexItemTitleVariants}
              className="ProjectIndex__title"
              initial="initial"
              animate="visible"
              exit="exit"
            >
              {title}
            </motion.div>
            {thumbFirst && (
              <motion.div
                variants={motionProjectIndexItemImageVariants}
                className="ProjectIndex__underlay"
                initial="initial"
                animate="visible"
                exit="exit"
              >
                <img
                  src={thumbFirst.largeUrl}
                  className="ProjectIndex__underlay__img"
                />
              </motion.div>
            )}
          </>
        )}
      </AnimatePresence>

      <motion.article
        variants={motionProjectIndexItemVariants}
        initial="initial"
        animate={animate}
        className={`ProjectIndex__item ProjectIndex__item--${animate}`}
        onMouseLeave={handleMouseLeave}
      >
        <Link className="ProjectIndex__item__link" to={url}>
          <figure className="ProjectIndex__item__figure">
            <div className="ProjectIndex__item__figure__ratio" />
            {thumbFirst && (
              <Image
                image={thumbFirst}
                className="ProjectIndex__item__figure__img"
              />
            )}
          </figure>
          <div
            className="ProjectIndex__item__title"
            onMouseEnter={handleMouseEnter}
          >
            <h2>{title}</h2>
          </div>
          <div
            className="ProjectIndex__item__meta"
            dangerouslySetInnerHTML={{ __html: meta }}
          />
          {activeCurrent && (
            <div className="ProjectIndex__item__abstract">
              {metaClean && (
                <div
                  className="ProjectIndex__item__abstract__meta"
                  dangerouslySetInnerHTML={{ __html: metaClean }}
                />
              )}
              <div
                className="ProjectIndex__item__abstract__text"
                dangerouslySetInnerHTML={{ __html: abstractShort }}
              />
            </div>
          )}
        </Link>
      </motion.article>
    </>
  )
}

export default ProjectIndexItem
