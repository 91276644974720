import React, { ReactElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { BlockAssetGridType, ImageType, VideoType } from 'data/types'
import { handleContentLinks } from 'utils/handleContentLinks'
import Image from 'view/components/image/Image'
import Video from 'view/components/video/Video'

import './BlockAssetGrid.scss'

const BlockAssetGrid = ({
  locale,
  paddingTop,
  layout,
  assets,
  caption,
}: BlockAssetGridType): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)
  const items = assets.map((item) => {
    const { id, kind } = item

    if (kind === 'image') {
      const image = item as ImageType

      return (
        <figure key={id} className="BlockAssetGrid__figure">
          <div className="BlockAssetGrid__ratio" />
          <Image image={image} className="BlockAssetGrid__img" />
        </figure>
      )
    } else if (kind === 'video') {
      const video = item as VideoType

      return (
        <div key={id} className="BlockAssetGrid__video">
          <Video video={video} ratio={1} threshold={0.2} />
        </div>
      )
    }

    return null
  })

  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockAssetGrid',
    `BlockAssetGrid--${layout}`
  )

  return (
    <div className={classes}>
      {items && <div className="BlockAssetGrid__items">{items}</div>}
      {caption && (
        <div
          className="BlockAssetGrid__caption"
          dangerouslySetInnerHTML={{ __html: caption }}
          onClick={handleContentClick}
        />
      )}
    </div>
  )
}

export default BlockAssetGrid
