import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router-dom'

import { RootState } from 'data/redux/rootReducer'
import {
  uiChangeCurrentInvertedUrl,
  uiChangeLoading,
} from 'data/redux/ui/actions'
import { mapLangToSiteId } from 'utils/i18n'
import PROJECT_QUERY from 'data/queries/project'
import NotFound from 'view/content/not-found/NotFound'
import Project from './Project'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
})

const mapDispatchToProps = {
  actionChangeCurrentInvertedUrl: uiChangeCurrentInvertedUrl,
  actionChangeLoading: uiChangeLoading,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ProjectProviderProps = ConnectedProps<typeof connector>

const ProjectProvider = ({
  projects,
  actionChangeCurrentInvertedUrl,
  actionChangeLoading,
}: ProjectProviderProps): ReactElement | null => {
  const { slug } = useParams()
  const { locale } = useIntl()

  const { data, loading } = useQuery(PROJECT_QUERY, {
    variables: {
      slug,
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    actionChangeLoading(loading)
  }, [loading])

  if (loading) {
    return null
  }

  if (data && data.result) {
    return (
      <Project
        locale={locale}
        projects={projects}
        actionChangeCurrentInvertedUrl={actionChangeCurrentInvertedUrl}
        {...data.result}
      />
    )
  }

  return <NotFound />
}

export default connector(ProjectProvider)
