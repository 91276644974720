import { combineReducers } from 'redux'

import content from './content/reducers'
import global from './global/reducers'
import ui from './ui/reducers'

export const rootReducer = combineReducers({
  content,
  global,
  ui,
})

export type RootState = ReturnType<typeof rootReducer>
