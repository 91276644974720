import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import moment from 'moment'
import striptags from 'striptags'
import trim from 'trim'

import './BlockRelatedEvent.scss'

const BlockRelatedEvent = ({
  locale,
  paddingTop,
  related,
}): ReactElement | null => {
  const { formatMessage } = useIntl()

  const relatedFirst = related && related.length > 0 && related[0]

  if (relatedFirst) {
    const { id, title, date, time, type } = relatedFirst

    const textTitle = formatMessage({
      id: 'BlockRelatedEvent_Title',
      defaultMessage: 'Dazu passende Veranstaltung',
    })

    const timeClean = trim(
      striptags(time, ['br', 'em', 'i', 'strong', 'b', 'sup', 'sub', 'a'])
    )

    let dateSpan = null

    if (date) {
      const dateDayOfWeekName = moment(date, 'DD.MM.YYYY')
        .locale(locale)
        .format('dddd')
      const dateDayMonth = moment(date, 'DD.MM.YYYY')
        .locale(locale)
        .format('DD. MMMM')

      dateSpan = (
        <>
          <span>{dateDayOfWeekName},&nbsp;</span>
          <span>
            <strong>{dateDayMonth}</strong>
          </span>
          {time && (
            <span
              className="Events__item__teaser__date__time"
              dangerouslySetInnerHTML={{ __html: ', ' + timeClean }}
            />
          )}
        </>
      )
    }

    const classes = classnames(
      'Block',
      paddingTop ? `h-padding-${paddingTop}` : '',
      'BlockRelatedEvent'
    )

    return (
      <div className={classes}>
        <div className="BlockRelatedEvent__title">{textTitle}</div>
        <div className="BlockRelatedEvent__contents">
          <Link
            to={`${
              locale === 'en' ? `/en/events?id=${id}` : `/de/events?id=${id}`
            }`}
            className="BlockRelatedEvent__contents__link"
          >
            <div className="BlockRelatedEvent__contents__date">{dateSpan}</div>
            <div className="BlockRelatedEvent__contents__title">{title}</div>
            <div className="BlockRelatedEvent__contents__meta">
              <div
                className="BlockRelatedEvent__contents__meta__type"
                dangerouslySetInnerHTML={{ __html: type }}
              />
              <div className="BlockRelatedEvent__contents__meta__icon">↙</div>
            </div>
          </Link>
        </div>
      </div>
    )
  }

  return null
}

export default BlockRelatedEvent
