import { AnyAction } from 'redux'

import { UIStateType } from 'data/types'
import {
  UI_CHANGE_CURRENT_INVERTED_URL,
  UI_CHANGE_LOADING,
  UI_CHANGE_HOME_VISITED,
  UI_CHANGE_INTRO,
} from './types'

const initialState: UIStateType = {
  currentInvertedUrl: '',
  showLoading: false,
  showIntro: false,
  homeVisited: false,
}

export default function (state = initialState, action: AnyAction): UIStateType {
  switch (action.type) {
    case UI_CHANGE_CURRENT_INVERTED_URL: {
      const { url } = action.payload

      if (url === state.currentInvertedUrl) {
        return state
      }

      return {
        ...state,
        currentInvertedUrl: url,
      }
    }

    case UI_CHANGE_LOADING: {
      const { show } = action.payload

      return {
        ...state,
        showLoading: show,
      }
    }

    case UI_CHANGE_HOME_VISITED: {
      const { visited } = action.payload

      return {
        ...state,
        homeVisited: visited,
      }
    }

    case UI_CHANGE_INTRO: {
      const { show } = action.payload

      return {
        ...state,
        showIntro: show,
      }
    }

    default:
      return state
  }
}
