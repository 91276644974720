import { ReactElement, useEffect } from 'react'

const ScrollToTop = ({ deactive = false }): ReactElement => {
  useEffect(() => {
    if (!deactive) {
      window.setTimeout(() => window.scrollTo(0, 0), 100)
    }
  }, [deactive])

  return null
}

export default ScrollToTop
