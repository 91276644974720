import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import {
  motionNotFoundContainerVariants,
  motionNotFoundItemVariants,
} from 'utils/motionVariants'
import { shuffleArray } from 'utils/shuffleArray'

import './NotFoundAnimation.scss'

const items = Array.from(Array(21).keys())
items.shift()
shuffleArray(items)

const NotFoundAnimation = ({ speed }) => {
  const [lower, setLower] = useState(0)
  const [higher, setHigher] = useState(5)

  useEffect(() => {
    const interval = window.setInterval(
      () => {
        setLower((prevLower) => (prevLower + 1 > 19 ? 0 : prevLower + 1))
        setHigher((prevHigher) => (prevHigher + 1 > 19 ? 0 : prevHigher + 1))
      },
      speed > 1 ? 150 : 500
    )

    return () => window.clearInterval(interval)
  })

  const itemsHtml = items.map((item, index) => {
    if (
      (higher > lower && index >= lower && index <= higher) ||
      (higher < lower && (index <= higher || index >= lower))
    ) {
      return (
        <motion.div
          key={item}
          variants={motionNotFoundItemVariants}
          custom={speed}
          className="NotFoundAnimation__item"
          style={{
            backgroundImage: 'url(/animation/error/' + item + '.png)',
            zIndex: (higher > lower ? higher : lower) + index,
          }}
        />
      )
    }

    return null
  })

  return (
    <motion.div
      className="NotFoundAnimation"
      variants={motionNotFoundContainerVariants}
      initial="show"
      animate="hidden"
    >
      {itemsHtml}
    </motion.div>
  )
}

export default NotFoundAnimation
