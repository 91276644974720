import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'

import {
  uiChangeCurrentInvertedUrl,
  uiChangeLoading,
} from 'data/redux/ui/actions'
import PRIVACY_QUERY from 'data/queries/privacy'
import { mapLangToSiteId } from 'utils/i18n'
import NotFound from 'view/content/not-found/NotFound'
import Privacy from './Privacy'

const mapDispatchToProps = {
  actionChangeCurrentInvertedUrl: uiChangeCurrentInvertedUrl,
  actionChangeLoading: uiChangeLoading,
}

const connector = connect(null, mapDispatchToProps)

type PrivacyProviderProps = ConnectedProps<typeof connector>

const PrivacyProvider = ({
  actionChangeCurrentInvertedUrl,
  actionChangeLoading,
}: PrivacyProviderProps): ReactElement => {
  const { locale } = useIntl()

  const { data, loading } = useQuery(PRIVACY_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    actionChangeCurrentInvertedUrl(
      locale === 'de' ? '/en/privacy' : '/de/datenschutz'
    )
  }, [locale])

  useEffect(() => {
    actionChangeLoading(loading)
  }, [loading])

  if (loading) {
    return null
  }

  if (data && data.result) {
    return <Privacy locale={locale} {...data.result} />
  }

  return <NotFound />
}

export default connector(PrivacyProvider)
