import { Action, ActionCreator } from 'redux'
import {
  UI_CHANGE_CURRENT_INVERTED_URL,
  UI_CHANGE_LOADING,
  UI_CHANGE_HOME_VISITED,
  UI_CHANGE_INTRO,
} from './types'

export const uiChangeCurrentInvertedUrl: ActionCreator<Action> = (
  url: string
) => ({
  type: UI_CHANGE_CURRENT_INVERTED_URL,
  payload: { url },
})

export const uiChangeLoading: ActionCreator<Action> = (show: boolean) => ({
  type: UI_CHANGE_LOADING,
  payload: { show },
})

export const uiChangeHomeVisited: ActionCreator<Action> = (
  visited: boolean
) => ({
  type: UI_CHANGE_HOME_VISITED,
  payload: { visited },
})

export const uiChangeIntro: ActionCreator<Action> = (show: boolean) => ({
  type: UI_CHANGE_INTRO,
  payload: { show },
})
