import { gql } from '@apollo/client'

const COMMENTS_QUERY = gql`
  query CommentsQuery {
    results: entries(section: "comments", commentApproved: true) {
      id
      ... on comments_comment_Entry {
        name: commentName
        comment: commentComment
        date: dateCreated
          @formatDateTime(format: "d.m.Y H:i", timezone: "Europe/Zurich")
        related: commentRelatedProject {
          id
        }
      }
    }
  }
`

export default COMMENTS_QUERY
