import { gql } from '@apollo/client'

import { COMMON_CONTENTS } from './fragments'

const PROJECT_EXPANDER_QUERY = gql`
  ${COMMON_CONTENTS}
  query ProjectExpanderQuery($slug: [String], $siteId: [QueryArgument]) {
    result: entry(section: "projects", slug: $slug, siteId: $siteId) {
      id
      siteId
      slug
      title
      ... on projects_project_Entry {
        abstract: projectAbstract
        contents: commonContents {
          ...CommonContents
        }
      }
    }
  }
`

export default PROJECT_EXPANDER_QUERY
