import { Action, ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { ReduxExtraArgumentsType } from 'data/types'
import { CONTENT_DATA_LOAD, CONTENT_SHUFFLE_PROJECTS } from './types'
import COMMENTS_QUERY from 'data/queries/comments'
import PROJECTS_QUERY from 'data/queries/projects'
import { mapLangToSiteId } from 'utils/i18n'

export const contentDataLoad: ActionCreator<
  ThunkAction<Promise<Action>, any, ReduxExtraArgumentsType, Action>
> = (locale: string) => {
  return async (
    dispatch: Dispatch,
    getState: any,
    { client }: ReduxExtraArgumentsType
  ) => {
    const response1 = await client.query({
      query: PROJECTS_QUERY,
      variables: {
        siteId: mapLangToSiteId(locale),
      },
    })

    const response2 = await client.query({
      query: COMMENTS_QUERY,
    })

    return dispatch({
      type: CONTENT_DATA_LOAD,
      payload: {
        projects: response1.data.results,
        comments: response2.data.results,
      },
    })
  }
}

export const contentShuffleProjects: ActionCreator<Action> = () => ({
  type: CONTENT_SHUFFLE_PROJECTS,
})
