import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'

const TagTopic = ({ topic }): ReactElement | null => {
  const { formatMessage } = useIntl()

  const textTopicTitle = formatMessage({
    id: 'Tag_Topic_Title',
    defaultMessage: 'Thema',
  })

  if (topic) {
    return (
      <div className="Tag__section">
        <div className="Tag__section__title">{textTopicTitle}</div>
        <div
          className="Tag__section__content h-type-topic"
          dangerouslySetInnerHTML={{ __html: topic }}
        />
      </div>
    )
  }

  return null
}

export default TagTopic
