import { gql } from '@apollo/client'

import { COMMON_CONTENTS } from './fragments'

const EVENTS_QUERY = gql`
  ${COMMON_CONTENTS}
  query EventsQuery($siteId: [QueryArgument]) {
    results: entries(section: "events", siteId: $siteId) {
      id
      siteId
      title
      ... on events_event_Entry {
        dateTech: eventDate
        date: eventDate
          @formatDateTime(format: "d.m.Y", timezone: "Europe/Zurich")
        time: eventTime
        type: eventType
        archive: eventArchive
        lead: eventLead
        text: eventText
        link: eventExternalLink
        linkText: eventExternalLinkText
        linkBefore: eventExternalLinkBefore
        related: eventRelatedProject {
          id
          slug
          title
          ... on projects_project_Entry {
            thumb: projectThumb {
              id
              url @transform(handle: "mini")
              title
              width
              height
            }
          }
        }
        thumb: eventThumb {
          id
          url @transform(handle: "large")
          title
          width @transform(handle: "large")
          height @transform(handle: "large")
        }
        contents: commonContents {
          ...CommonContents
        }
      }
    }
  }
`

export default EVENTS_QUERY
