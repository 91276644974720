import React, { ReactElement, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import { BASE_URL } from 'Constants'
import { ProjectIndexType, ProjectType } from 'data/types'
import { handleContentLinks } from 'utils/handleContentLinks'
import { mapLangToSiteId } from 'utils/i18n'
import { motionPageVariants } from 'utils/motionVariants'
import Blocks from 'view/components/blocks/Blocks'
import Helmet from 'view/components/helmet/DefaultHelmet'
import ScrollToTop from 'view/components/scroll-to-top/ScrollToTop'
import ProjectsSlideshow from './ProjectsSlideshow'

import './Project.scss'

interface ProjectProps extends ProjectType {
  projects: ProjectIndexType[]
  actionChangeCurrentInvertedUrl: (url: string) => void
}

const Project = ({
  locale,
  projects,
  localized,
  id,
  slug,
  title,
  abstract,
  contents,
  actionChangeCurrentInvertedUrl,
}: ProjectProps): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const otherSiteId =
      locale === 'de' ? mapLangToSiteId('en') : mapLangToSiteId('de')
    const localizedEntry = localized.find((item) => item.siteId == otherSiteId)

    if (localizedEntry) {
      actionChangeCurrentInvertedUrl(
        locale === 'de'
          ? `/en/project/${localizedEntry.slug}`
          : `/de/projekt/${localizedEntry.slug}`
      )
    }
  }, [locale, localized])

  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const blocks = (
    <Blocks
      contents={contents}
      projects={projects}
      locale={locale}
      projectId={id}
      allowExpanders={true}
    />
  )

  const helmetParams = {
    title: title,
    description: abstract,
    url:
      locale === 'de'
        ? `${BASE_URL}/de/projekt/${slug}`
        : `${BASE_URL}/en/project/${slug}`,
  }

  return (
    <>
      <Helmet {...helmetParams} />
      <ScrollToTop />
      <motion.section
        className="Project"
        variants={motionPageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <div className="Project__title">{title}</div>
        <div
          className="Project__abstract"
          dangerouslySetInnerHTML={{ __html: abstract }}
          onClick={handleContentClick}
        />
        {blocks}

        <ProjectsSlideshow currentProjectId={id} />
      </motion.section>
    </>
  )
}

export default Project
