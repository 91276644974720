import { useQuery } from '@apollo/client'
import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import classnames from 'classnames'

import PRESS_QUERY from 'data/queries/press'
import { mapLangToSiteId } from 'utils/i18n'
import { handleContentLinks } from 'utils/handleContentLinks'
import Image from 'view/components/image/Image'

import './BlockPress.scss'

const BlockPressItem = ({
  locale,
  date,
  publisher,
  url,
  thumb,
  lead,
  text,
}): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  let dateSpan = null

  if (date) {
    const dateDayMonth = moment(`${date}`, 'DD.MM.YYYY')
      .locale(locale)
      .format('DD. MMMM')
    const dateYear = moment(`${date}`, 'DD.MM.YYYY')
      .locale(locale)
      .format('YYYY')

    dateSpan = (
      <span className="BlockPress__item__meta__date">
        <strong>{dateDayMonth}</strong> {dateYear}
      </span>
    )
  }

  let publisherSpan = null

  if (publisher) {
    if (url) {
      publisherSpan = (
        <>
          {date && <span>, </span>}
          <span className="BlockPress__item__meta__publisher">
            <a
              className="BlockPress__item__meta__publisher__link"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              title={publisher}
            >
              {publisher}
            </a>
          </span>
        </>
      )
    } else {
      publisherSpan = (
        <>
          {date && <span>,</span>}
          <span className="BlockPress__item__meta__publisher">{publisher}</span>
        </>
      )
    }
  }

  // 1. meta column
  const metaColumn = (
    <div className="BlockPress__item__meta">
      {dateSpan}
      {publisherSpan}
    </div>
  )

  const thumbFirst = thumb && thumb.length > 0 && thumb[0]
  let thumbColumn = <div className="BlockPress__item__figure__empty" />

  if (thumbFirst) {
    thumbColumn = (
      <figure className="BlockPress__item__figure">
        <Image image={thumbFirst} className="BlockPress__item__figure__img" />
      </figure>
    )
  }

  const contentColumn = (
    <div className="BlockPress__item__content">
      {lead && (
        <div
          className="BlockPress__item__lead"
          dangerouslySetInnerHTML={{ __html: lead }}
          onClick={handleContentClick}
        />
      )}
      {text && (
        <div
          className="BlockPress__item__text"
          dangerouslySetInnerHTML={{ __html: text }}
          onClick={handleContentClick}
        />
      )}
    </div>
  )

  return (
    <div className="BlockPress__item">
      {metaColumn}
      {thumbColumn}
      {contentColumn}
    </div>
  )
}

const BlockPress = ({ locale, paddingTop }): ReactElement | null => {
  const { formatMessage } = useIntl()
  const { data, loading } = useQuery(PRESS_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  if (loading) {
    return <span>Loading…</span>
  }

  if (data && data.results) {
    const textTitle = formatMessage({
      id: 'BlockPress_Title',
      defaultMessage: 'Pressestimmen',
    })

    const items = data.results.map((item) => (
      <BlockPressItem key={item.id} locale={locale} {...item} />
    ))

    const classes = classnames(
      'Block',
      paddingTop ? `h-padding-${paddingTop}` : '',
      'BlockPress'
    )

    return (
      <div className={classes}>
        <div className="BlockPress__title">{textTitle}</div>
        {items && <div className="BlockPress__content">{items}</div>}
      </div>
    )
  }

  return null
}

export default BlockPress
