import React, { ReactElement, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper'
import classnames from 'classnames'

import { handleContentLinks } from 'utils/handleContentLinks'

import 'swiper/css'
import './BlockRelatedResearch.scss'

const BlockRelatedResearch = ({
  locale,
  paddingTop,
  citationSource,
  citationLink,
  abstract,
  text,
}): ReactElement | null => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { formatMessage } = useIntl()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const textTitle = formatMessage({
    id: 'BlockRelatedResearch_Title',
    defaultMessage: 'Verwandte Forschungsarbeiten',
  })

  const citationLinkText = citationLink
    ? citationLink.replace(/(^\w+:|^)\/\//, '').replace('www.', '')
    : null

  const citationHtml =
    citationSource || citationLink ? (
      <div className="BlockRelatedResearch__citation">
        <div className="BlockRelatedResearch__citation__content">
          {citationSource && (
            <div
              className="BlockRelatedResearch__citation__source"
              dangerouslySetInnerHTML={{ __html: citationSource }}
            />
          )}
          {citationLink && (
            <div className="BlockRelatedResearch__citation__link">
              <a href={citationLink} target="_blank" rel="noopener noreferrer">
                {citationLinkText} ↗
              </a>
            </div>
          )}
        </div>
      </div>
    ) : null

  const slideshow = (
    <Swiper
      modules={[FreeMode, Navigation]}
      freeMode={{ enabled: true, sticky: true }}
      slidesPerView="auto"
      spaceBetween={10}
      navigation={{
        nextEl: nextRef.current,
        prevEl: prevRef.current,
      }}
    >
      <SwiperSlide className="BlockRelatedResearch__slideshow__item h-abstract">
        <div
          className="BlockRelatedResearch__abstract"
          dangerouslySetInnerHTML={{ __html: abstract }}
          onClick={handleContentClick}
        />
      </SwiperSlide>
      <SwiperSlide className="BlockRelatedResearch__slideshow__item h-text">
        <div
          className="BlockRelatedResearch__text"
          dangerouslySetInnerHTML={{ __html: text }}
          onClick={handleContentClick}
        />
      </SwiperSlide>
    </Swiper>
  )

  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockRelatedResearch'
  )

  return (
    <div className={classes}>
      <div className="BlockRelatedResearch__title">{textTitle}</div>
      {citationHtml}
      <div className="BlockRelatedResearch__slideshow">
        {slideshow}

        <div
          className="BlockRelatedResearch__slideshow__button__left"
          ref={prevRef}
        >
          <div className="h-slideshow-button-bg" />
          <div className="h-slideshow-button-icon">←</div>
        </div>

        <div
          className="BlockRelatedResearch__slideshow__button__right"
          ref={nextRef}
        >
          <div className="h-slideshow-button-bg" />
          <div className="h-slideshow-button-icon">→</div>
        </div>
      </div>
    </div>
  )
}

export default BlockRelatedResearch
