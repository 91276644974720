import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const TagProjects = ({ locale, title, projects }): ReactElement | null => {
  const { formatMessage } = useIntl()

  const textProjectsTitle = formatMessage({
    id: 'Tag_Related_Projects_Title',
    defaultMessage: 'Artikel zum Thema',
  })

  if (projects && projects.length > 0) {
    const items = projects.map((item) => (
      <div key={item.id} className="Tag__project">
        <Link
          className="Tag__project__link"
          to={`${
            locale === 'en'
              ? `/en/project/${item.slug}`
              : `/de/projekt/${item.slug}`
          }`}
        >
          {item.title}
        </Link>
      </div>
    ))

    return (
      <div className="Tag__section Tag__section--padding-zero">
        <div className="Tag__section__title">
          <span className="Tag__section__title__hint">
            {textProjectsTitle}{' '}
          </span>
          <span className="Tag__section__title__content">{title}</span>
        </div>
        <div className="Tag__section__content h-type-projects">{items}</div>
      </div>
    )
  }

  return null
}

export default TagProjects
