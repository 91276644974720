import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import striptags from 'striptags'
import trim from 'trim'

const TagExcerpts = ({ locale, excerpts }): ReactElement => {
  const { formatMessage } = useIntl()

  const textExcerptsTitle = formatMessage({
    id: 'Tag_Excerpts_Title',
    defaultMessage: 'Textausschnitte',
  })

  const textExcerptsInProject = formatMessage({
    id: 'Tag_Excerpts_In_Project',
    defaultMessage: 'in',
  })

  let hasContent = false

  const items = excerpts.map((item) => {
    const { id, hidden, text, related } = item

    if (hidden) return null

    hasContent = true
    const textClean = trim(
      striptags(text, ['br', 'em', 'i', 'strong', 'b', 'sup', 'sub', 'a'])
    )

    const relatedFirst = related && related.length > 0 && related[0]
    let relatedHtml = null

    if (relatedFirst) {
      relatedHtml = (
        <div className="Tag__excerpt__related">
          <span className="Tag__excerpt__related__hint">
            {textExcerptsInProject}
          </span>{' '}
          <span className="Tag__excerpt__related__content">
            <Link
              to={`${
                locale === 'en'
                  ? `/en/project/${relatedFirst.slug}`
                  : `/de/projekt/${relatedFirst.slug}`
              }`}
            >
              {relatedFirst.title}
            </Link>
          </span>
        </div>
      )
    }

    return (
      <div key={id} className="Tag__excerpt">
        {relatedHtml}
        <div className="Tag__excerpt__text">
          <span className="Tag__excerpt__text__dots">… </span>
          <span
            className="Tag__excerpt__text__content"
            dangerouslySetInnerHTML={{ __html: textClean }}
          />
          <span className="Tag__excerpt__text__dots">…</span>
        </div>
      </div>
    )
  })

  if (hasContent) {
    return (
      <div className="Tag__section">
        <div className="Tag__section__title">{textExcerptsTitle}</div>
        <div className="Tag__section__content h-type-excerpt">{items}</div>
      </div>
    )
  }

  return null
}

export default TagExcerpts
