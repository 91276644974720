import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'

import { IS_DEV } from 'Constants'
import { RootState } from 'data/redux/rootReducer'

const mapStateToProps = (state: RootState) => ({
  googleAnalyticsId: state.global.googleAnalyticsId,
})

const connector = connect(mapStateToProps)

type AnalyticsProps = ConnectedProps<typeof connector>

const Analytics = ({ googleAnalyticsId }: AnalyticsProps): null => {
  const { pathname } = useLocation()

  if (googleAnalyticsId) {
    ReactGA.initialize(googleAnalyticsId, { debug: IS_DEV })
  }

  useEffect(() => {
    if (googleAnalyticsId) {
      ReactGA.pageview(pathname)
    }
  }, [googleAnalyticsId, pathname])

  return null
}

export default connector(Analytics)
