import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { handleContentLinks } from 'utils/handleContentLinks'
import BlockAudioTrack from './BlockAudioTrack'

import './BlockAudio.scss'

const BlockAudio = ({ locale, paddingTop, related }): ReactElement | null => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const relatedFirst = related && related.length > 0 && related[0]

  if (relatedFirst) {
    const { title, description, duration, tracks } = relatedFirst

    const textDuration = formatMessage({
      id: 'BlockAudio_Duration',
      defaultMessage: 'Dauer:',
    })

    const titleHtml = <div className="BlockAudio__title">{title}</div>
    const descriptionHtml = description && (
      <div
        className="BlockAudio__description"
        dangerouslySetInnerHTML={{ __html: description }}
        onClick={handleContentClick}
      />
    )
    const durationHtml = duration && (
      <div className="BlockAudio__duration">
        <div className="BlockAudio__duration__hint">{textDuration} </div>
        <div className="BlockAudio__duration__text">&nbsp;{duration}</div>
      </div>
    )

    let totalTracks = 0
    const tracksHtml = tracks.map((item, index) => {
      if (item.hidden) return null

      const track = item.track && item.track.length > 0 && item.track[0]

      if (track) {
        totalTracks++

        return (
          <BlockAudioTrack
            key={item.id}
            index={index}
            title={item.title}
            track={track}
          />
        )
      }

      return null
    })

    const classes = classnames(
      'Block',
      paddingTop ? `h-padding-${paddingTop}` : '',
      'BlockAudio',
      {
        'BlockAudio--multiple': totalTracks > 1,
      }
    )

    return (
      <div className={classes}>
        {titleHtml}
        {descriptionHtml}
        {durationHtml}
        {tracksHtml && <div className="BlockAudio__tracks">{tracksHtml}</div>}
      </div>
    )
  }

  return null
}

export default BlockAudio
