import { gql } from '@apollo/client'

const PRIVACY_QUERY = gql`
  query PrivacyQuery($siteId: [QueryArgument]) {
    result: entry(section: "privacy", siteId: $siteId) {
      id
      siteId
      ... on privacy_privacy_Entry {
        title: privacyTitle
        contents: privacyContents {
          ... on privacyContents_text_BlockType {
            id
            siteId
            hidden: blockHidden
            title: blockTitle
            content: blockContent
          }
        }
      }
    }
  }
`

export default PRIVACY_QUERY
