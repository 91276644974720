import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'

import { ImprintType } from 'data/types'
import { motionPageVariants } from 'utils/motionVariants'
import Blocks from 'view/components/blocks/Blocks'

import './Imprint.scss'

const Imprint = ({ locale, projects, contents }: ImprintType): ReactElement => (
  <motion.section
    className="Imprint"
    variants={motionPageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <Blocks
      contents={contents}
      projects={projects}
      locale={locale}
      projectId={null}
      allowExpanders={true}
    />
  </motion.section>
)

export default Imprint
