import React, { ReactElement, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper'

import { BREAKPOINT_S } from 'Constants'
import { handleContentLinks } from 'utils/handleContentLinks'
import { useWindowSize } from 'utils/useWindowSize'
import Image from 'view/components/image/Image'

import 'swiper/css'
import './BlockSlideshow.scss'

const BlockSlideshow = ({
  locale,
  paddingTop,
  title,
  images,
  caption,
}): ReactElement => {
  const windowSize = useWindowSize()
  const [swiper, setSwiper] = useState(null)
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const imgOnLoad = () => {
    if (swiper) {
      swiper.update()
    }
  }

  const slideshowItems = images.map((image) => (
    <SwiperSlide key={image.id} className="BlockSlideshow__slideshow__item">
      <figure className="BlockSlideshow__slideshow__item__figure">
        <Image
          image={image}
          className="BlockSlideshow__slideshow__item__figure__img"
          onLoad={imgOnLoad}
        />
      </figure>
    </SwiperSlide>
  ))

  const slideshow = (
    <Swiper
      modules={[FreeMode, Navigation]}
      slidesOffsetBefore={windowSize.width <= BREAKPOINT_S ? 38 : 50}
      freeMode
      slidesPerView="auto"
      spaceBetween={10}
      navigation={{
        nextEl: nextRef.current,
        prevEl: prevRef.current,
      }}
      onSwiper={setSwiper}
    >
      {slideshowItems}
    </Swiper>
  )

  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockSlideshow'
  )

  return (
    <div className={classes}>
      <div className="BlockSlideshow__title">{title}</div>
      <div className="BlockSlideshow__slideshow">
        {slideshow}

        <div className="BlockSlideshow__slideshow__button__left" ref={prevRef}>
          <div className="h-slideshow-button-bg" />
          <div className="h-slideshow-button-icon">←</div>
        </div>

        <div className="BlockSlideshow__slideshow__button__right" ref={nextRef}>
          <div className="h-slideshow-button-bg" />
          <div className="h-slideshow-button-icon">→</div>
        </div>
      </div>
      {caption && (
        <div
          className="BlockSlideshow__caption"
          dangerouslySetInnerHTML={{ __html: caption }}
          onClick={handleContentClick}
        />
      )}
    </div>
  )
}

export default BlockSlideshow
