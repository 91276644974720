import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  defaultDataIdFromObject,
  NormalizedCacheObject,
  from,
  DefaultOptions,
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import fetch from 'isomorphic-unfetch'

import { GRAPHQL_ENDPOINT, IS_SERVER_SIDE } from 'Constants'
import possibleTypesCms from './possibleTypesCms.json'

// need to take siteId into account, otherwiste not possible
// to differentiate between EN/DE contents
const dataIdFromObject = (responseObject: any) => {
  switch (responseObject.__typename) {
    case 'commonContents_assetGrid_BlockType':
    case 'commonContents_audio_BlockType':
    case 'commonContents_custom_BlockType':
    case 'commonContents_image_BlockType':
    case 'commonContents_index_BlockType':
    case 'commonContents_linkList_BlockType':
    case 'commonContents_meta_BlockType':
    case 'commonContents_press_BlockType':
    case 'commonContents_quote_BlockType':
    case 'commonContents_references_BlockType':
    case 'commonContents_relatedEvent_BlockType':
    case 'commonContents_relatedProject_BlockType':
    case 'commonContents_relatedResearch_BlockType':
    case 'commonContents_slideshow_BlockType':
    case 'commonContents_text_BlockType':
    case 'commonContents_video_BlockType':
      return `common:${responseObject.id}:${responseObject.siteId}`
    case 'events_event_Entry':
      return `events:${responseObject.id}:${responseObject.siteId}`
    case 'information_information_Entry':
      return `information:${responseObject.id}:${responseObject.siteId}`
    case 'globalConfig_GlobalSet':
      return `globalConfig:${responseObject.id}:${responseObject.siteId}`
    case 'home_home_Entry':
      return `home:${responseObject.id}:${responseObject.siteId}`
    case 'index_index_Entry':
      return `index:${responseObject.id}:${responseObject.siteId}`
    case 'meta_meta_Entry':
      return `meta:${responseObject.id}:${responseObject.siteId}`
    case 'metaContents_column_BlockType':
      return `metaContents:${responseObject.id}:${responseObject.siteId}`
    case 'press_press_Entry':
      return `press:${responseObject.id}:${responseObject.siteId}`
    case 'privacy_privacy_Entry':
      return `privacy:${responseObject.id}:${responseObject.siteId}`
    case 'privacyContents_text_BlockType':
      return `privacyContents:${responseObject.id}:${responseObject.siteId}`
    case 'projects_project_Entry':
      return `project:${responseObject.id}:${responseObject.siteId}`
    case 'references_reference_Entry':
      return `reference:${responseObject.id}:${responseObject.siteId}`
    case 'referenceContents_reference_BlockType':
      return `referenceContents:${responseObject.id}:${responseObject.siteId}`
    case 'tags_tag_Entry':
      return `tag:${responseObject.id}:${responseObject.siteId}`
    default:
      return defaultDataIdFromObject(responseObject)
  }
}

const createClient = (token: string): ApolloClient<NormalizedCacheObject> => {
  const linkCms = new HttpLink({
    uri: `${GRAPHQL_ENDPOINT}${token ? `?token=${token}` : ``}`,
    credentials: 'same-origin',
    // Use fetch() polyfill on the server
    fetch: (IS_SERVER_SIDE && fetch) || undefined,
  })

  const cacheCms = IS_SERVER_SIDE
    ? new InMemoryCache({
        possibleTypes: possibleTypesCms,
        dataIdFromObject,
      })
    : new InMemoryCache({
        possibleTypes: possibleTypesCms,
        dataIdFromObject,
      }).restore((window as any).__APOLLO_STATE__)

  const ignoreCache: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const clientCms = new ApolloClient({
    ssrMode: IS_SERVER_SIDE,
    link: from([new RetryLink(), linkCms]),
    cache: cacheCms,
    // @NOTE ignore apollo cache completely if it is a CMS preview request
    defaultOptions: token ? ignoreCache : undefined,
  })

  return clientCms
}

export default createClient
