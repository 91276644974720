import { useQuery } from '@apollo/client'
import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import INDEX_QUERY from 'data/queries/index'
import { handleContentLinks } from 'utils/handleContentLinks'
import { mapLangToSiteId } from 'utils/i18n'
import Image from 'view/components/image/Image'

import './BlockIndex.scss'

const BlockIndexItem = ({ locale, title, related, description }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const relatedFirst = related && related.length > 0 && related[0]

  let metaHtml = null

  if (relatedFirst) {
    const { slug, thumb } = relatedFirst
    let thumbFirst = thumb && thumb.length > 0 && thumb[0]
    let thumbHtml = null

    if (thumbFirst) {
      thumbHtml = (
        <Image image={thumbFirst} className="BlockIndex__item__meta__thumb" />
      )
    }

    metaHtml = (
      <div className="BlockIndex__item__meta">
        <Link
          to={`${
            locale === 'en' ? `/en/project/${slug}` : `/de/projekt/${slug}`
          }`}
          className="BlockIndex__item__meta__link"
        >
          <div className="BlockIndex__item__meta__title">{title}</div>
          {thumbHtml}
        </Link>
      </div>
    )
  }

  const descriptionHtml = (
    <div className="BlockIndex__item__content">
      <div
        className="BlockIndex__item__content__description"
        dangerouslySetInnerHTML={{ __html: description }}
        onClick={handleContentClick}
      />
    </div>
  )

  return (
    <div className="BlockIndex__item">
      {metaHtml}
      {descriptionHtml}
    </div>
  )
}

const BlockIndex = ({ locale, paddingTop }): ReactElement | null => {
  const { formatMessage } = useIntl()
  const { data, loading } = useQuery(INDEX_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  if (loading) {
    return <span>Loading…</span>
  }

  if (data && data.results) {
    const textTitle = formatMessage({
      id: 'BlockIndex_Title',
      defaultMessage: 'Beteiligte Forscher:innen und Gestalter:innen',
    })

    const items = data.results.map((item) => (
      <BlockIndexItem key={item.id} locale={locale} {...item} />
    ))

    const classes = classnames(
      'Block',
      paddingTop ? `h-padding-${paddingTop}` : '',
      'BlockIndex'
    )

    return (
      <div className={classes}>
        <div className="BlockIndex__title">{textTitle}</div>
        <div className="BlockIndex__content">{items}</div>
      </div>
    )
  }

  return null
}

export default BlockIndex
