import React, { ReactElement } from 'react'
import classnames from 'classnames'

import { BASE_URL } from 'Constants'

import './BlockCustom.scss'

const BlockCustom = ({
  locale,
  paddingTop,
  title,
  component,
}): ReactElement => {
  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockCustom'
  )

  let customHtml = null

  if (component === 'chiffre') {
    customHtml = (
      <div className="BlockCustom__iframe">
        <div
          className="BlockCustom__iframe__ratio"
          style={{ paddingTop: '80%' }}
        ></div>
        <iframe
          className="BlockCustom__iframe__content"
          width="100%"
          src={`${BASE_URL}/jssubstitution/index.html?header=off&lang=${locale}`}
        />
      </div>
    )
  }

  return (
    <div className={classes}>
      <div className="BlockCustom__title">{title}</div>
      {customHtml && <div className="BlockCustom__content">{customHtml}</div>}
    </div>
  )
}

export default BlockCustom
