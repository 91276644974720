import React, { ReactElement } from 'react'
import { IntlProvider } from 'react-intl'
import { useMatch } from 'react-router-dom'

import { defaultLang, messages, determineUserLang } from 'utils/i18n'
import { LangParamsType } from 'data/types'
import App from 'view/App'

const AppWithIntlProvider = (): ReactElement => {
  const match: LangParamsType | null = useMatch('/:lang')

  let lang = defaultLang

  if (match && match.params && match.params.lang) {
    lang = determineUserLang([match.params.lang])
  } else {
    lang = determineUserLang(
      window.navigator.languages || [],
      window.location.pathname
    )
  }

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
      defaultLocale={defaultLang}
    >
      <App />
    </IntlProvider>
  )
}

export default AppWithIntlProvider
