import React, { ReactElement, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper'

import { BREAKPOINT_S } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import { contentShuffleProjects } from 'data/redux/content/actions'
import { useWindowSize } from 'utils/useWindowSize'
import Image from 'view/components/image/Image'

import 'swiper/css'
import './ProjectsSlideshow.scss'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
})

const mapDispatchToProps = {
  actionShuffleProjects: contentShuffleProjects,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface ProjectsSlideshowProps extends PropsFromRedux {
  currentProjectId: string
}

const ProjectsSlideshow = ({
  currentProjectId,
  projects,
  actionShuffleProjects,
}: ProjectsSlideshowProps): ReactElement => {
  const windowSize = useWindowSize()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const { formatMessage, locale } = useIntl()

  useEffect(() => {
    actionShuffleProjects()
  }, [])

  const textTitle = formatMessage({
    id: 'ProjectsSlideshow_Title',
    defaultMessage: 'Alle Projekte',
  })

  const slideshowItems = projects.map((item) => {
    const { id, slug, title, indexHidden, meta, thumb } = item
    const url = locale === 'de' ? `/de/projekt/${slug}` : `/en/project/${slug}`
    const thumbFirst = thumb && thumb.length > 0 && thumb[0]
    let slideWidth = 300

    // do not show index hidden project
    if (indexHidden) return null

    // do not show the current project
    if (id === currentProjectId) return null

    if (thumbFirst) {
      slideWidth = (300 / thumbFirst.height) * thumbFirst.width
    }

    return (
      <SwiperSlide key={id} style={{ width: slideWidth }}>
        <article className="ProjectsSlideshow__item">
          <Link className="ProjectsSlideshow__item__link" to={url}>
            <figure className="ProjectsSlideshow__item__figure">
              {thumbFirst && (
                <Image
                  image={thumbFirst}
                  className="ProjectsSlideshow__item__figure__img"
                />
              )}
            </figure>
            <div className="ProjectsSlideshow__item__title">
              <h2>{title}</h2>
            </div>
            <div
              className="ProjectsSlideshow__item__meta"
              dangerouslySetInnerHTML={{ __html: meta }}
            />
          </Link>
        </article>
      </SwiperSlide>
    )
  })

  const slideshow = (
    <Swiper
      modules={[FreeMode, Navigation]}
      slidesOffsetBefore={windowSize.width <= BREAKPOINT_S ? 38 : 50}
      freeMode={{
        enabled: true,
        sticky: true,
      }}
      slidesPerView="auto"
      spaceBetween={10}
      navigation={{
        nextEl: nextRef.current,
        prevEl: prevRef.current,
      }}
    >
      {slideshowItems}
    </Swiper>
  )

  return (
    <aside className="ProjectsSlideshow">
      <div className="ProjectsSlideshow__title">{textTitle}</div>
      <div className="ProjectsSlideshow__slideshow">
        {slideshow}

        <div
          className="ProjectsSlideshow__slideshow__button__left"
          ref={prevRef}
        >
          <div className="h-slideshow-button-bg" />
          <div className="h-slideshow-button-icon">←</div>
        </div>

        <div
          className="ProjectsSlideshow__slideshow__button__right"
          ref={nextRef}
        >
          <div className="h-slideshow-button-bg" />
          <div className="h-slideshow-button-icon">→</div>
        </div>
      </div>
    </aside>
  )
}

export default connector(ProjectsSlideshow)
