import React, { ReactElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { handleContentLinks } from 'utils/handleContentLinks'
import { addThumbsToProjectLinks } from 'utils/addThumbsToProjectLinks'

import './BlockText.scss'

const BlockText = ({
  locale,
  projects,
  paddingTop,
  title,
  content,
  contentIndent,
}): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const classes = classnames(
    'Block',
    paddingTop ? `h-padding-${paddingTop}` : '',
    'BlockText',
    { 'h-content-indent': contentIndent }
  )

  const contentProcessed = addThumbsToProjectLinks(content, projects)

  return (
    <div className={classes}>
      {title && <div className="BlockText__title">{title}</div>}
      <div className="BlockText__grid">
        <div
          className="BlockText__content"
          dangerouslySetInnerHTML={{ __html: contentProcessed }}
          onClick={handleContentClick}
        />
      </div>
    </div>
  )
}

export default BlockText
