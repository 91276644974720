import React, { ReactElement, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useMatch } from 'react-router-dom'
import { motion } from 'framer-motion'

import { RootState } from 'data/redux/rootReducer'
import { motionCookieBannerVariants } from 'utils/motionVariants'

import './CookieBanner.scss'

const mapStateToProps = (state: RootState) => ({
  showIntro: state.ui.showIntro,
})

const connector = connect(mapStateToProps)

type CookieBannerProps = ConnectedProps<typeof connector>

const CookieBanner = ({
  showIntro,
}: CookieBannerProps): ReactElement | null => {
  const [accepted, setAccepted] = useState(false)
  const [acceptedCookie, setAcceptedCookie] = useState(false)
  const { formatMessage, locale } = useIntl()
  const isPrivacyPageDE = useMatch('/de/datenschutz')
  const isPrivacyPageEN = useMatch('/en/privacy')

  useEffect(() => {
    const acceptedItem =
      localStorage && localStorage.getItem('planetdigital_privacy_accepted')
    const isAccepted = acceptedItem === 'yeah'

    setAcceptedCookie(isAccepted)
  }, [setAcceptedCookie])

  useEffect(() => {
    if (!accepted && !acceptedCookie) {
      document.body.classList.add('h-has-cookie-banner')
    } else {
      document.body.classList.remove('h-has-cookie-banner')
    }
  }, [accepted, acceptedCookie])

  const acceptCookies = () => {
    localStorage.setItem('planetdigital_privacy_accepted', 'yeah')
    setAccepted(true)
  }

  const textTitle = formatMessage({
    id: 'CookieBanner_Title',
    defaultMessage: 'Wir benutzen',
  })

  const textRead = formatMessage({
    id: 'CookieBanner_Read',
    defaultMessage: 'Cookies',
  })

  const url = locale === 'de' ? '/de/datenschutz' : '/en/privacy'

  if (isPrivacyPageDE || isPrivacyPageEN) {
    return null
  }

  if (!accepted && !acceptedCookie) {
    return (
      <motion.div
        className="CookieBanner"
        variants={motionCookieBannerVariants}
        initial="hidden"
        animate={showIntro ? 'hidden' : 'show'}
      >
        <div className="CookieBanner__text">{textTitle}&nbsp;</div>
        <Link to={url} className="CookieBanner__link">
          {textRead}
        </Link>
        <button onClick={acceptCookies} className="CookieBanner__accept">
          ×
        </button>
      </motion.div>
    )
  }

  return null
}

export default connector(CookieBanner)
