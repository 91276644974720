import React, { ReactElement } from 'react'

import { ProjectIndexType } from 'data/types'
import BlockAssetGrid from './BlockAssetGrid'
import BlockAudio from './BlockAudio'
import BlockComments from './BlockComments'
import BlockCustom from './BlockCustom'
import BlockEvents from './BlockEvents'
import BlockExpander from './BlockExpander'
import BlockImage from './BlockImage'
import BlockIndex from './BlockIndex'
import BlockLinkList from './BlockLinkList'
import BlockMeta from './BlockMeta'
import BlockPress from './BlockPress'
import BlockQuote from './BlockQuote'
import BlockReferences from './BlockReferences'
import BlockRelatedEvent from './BlockRelatedEvent'
import BlockRelatedProject from './BlockRelatedProject'
import BlockRelatedResearch from './BlockRelatedResearch'
import BlockSlideshow from './BlockSlideshow'
import BlockText from './BlockText'
import BlockVideo from './BlockVideo'

import './Blocks.scss'

type BlocksProps = {
  contents: any
  projects: ProjectIndexType[]
  locale: string
  projectId: string | null
  allowExpanders: boolean
}

const Blocks = ({
  contents,
  projects,
  locale,
  projectId,
  allowExpanders,
}: BlocksProps): ReactElement => {
  // comments can only exist once per blocks
  let displayedComments = false

  const blocks = contents.map((item) => {
    const { id, type, hidden } = item

    if (hidden) return null

    if (type === 'commonContents_assetGrid_BlockType') {
      return <BlockAssetGrid key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_audio_BlockType') {
      return <BlockAudio key={id} locale={locale} {...item} />
    } else if (
      type === 'commonContents_comments_BlockType' &&
      projectId &&
      !displayedComments
    ) {
      displayedComments = true

      return (
        <BlockComments
          key={id}
          locale={locale}
          projectId={projectId}
          {...item}
        />
      )
    } else if (type === 'commonContents_custom_BlockType') {
      return <BlockCustom key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_events_BlockType') {
      return (
        <BlockEvents key={id} locale={locale} projects={projects} {...item} />
      )
    } else if (type === 'commonContents_expander_BlockType' && allowExpanders) {
      return <BlockExpander key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_image_BlockType') {
      return <BlockImage key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_index_BlockType') {
      return <BlockIndex key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_linkList_BlockType') {
      return <BlockLinkList key={id} {...item} />
    } else if (type === 'commonContents_meta_BlockType') {
      return <BlockMeta key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_press_BlockType') {
      return <BlockPress key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_quote_BlockType') {
      return <BlockQuote key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_references_BlockType') {
      return <BlockReferences key={id} {...item} />
    } else if (type === 'commonContents_relatedEvent_BlockType') {
      return <BlockRelatedEvent key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_relatedProject_BlockType') {
      return <BlockRelatedProject key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_relatedResearch_BlockType') {
      return <BlockRelatedResearch key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_slideshow_BlockType') {
      return <BlockSlideshow key={id} locale={locale} {...item} />
    } else if (type === 'commonContents_text_BlockType') {
      return (
        <BlockText key={id} locale={locale} projects={projects} {...item} />
      )
    } else if (type === 'commonContents_video_BlockType') {
      return <BlockVideo key={id} locale={locale} {...item} />
    }

    return null
  })

  return <>{blocks}</>
}

export default Blocks
