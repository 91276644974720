import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'

import { ExhibitionsType } from 'data/types'
import { motionPageVariants } from 'utils/motionVariants'
import Blocks from 'view/components/blocks/Blocks'

import './Exhibitions.scss'

const Exhibitions = ({
  locale,
  projects,
  contents,
}: ExhibitionsType): ReactElement => (
  <motion.section
    className="Exhibitions"
    variants={motionPageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <Blocks
      contents={contents}
      projects={projects}
      locale={locale}
      projectId={null}
      allowExpanders={true}
    />
  </motion.section>
)

export default Exhibitions
