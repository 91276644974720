import { gql } from '@apollo/client'

import { COMMON_CONTENTS } from './fragments'

const EXHIBITIONS_PAGE_QUERY = gql`
  ${COMMON_CONTENTS}
  query ExhibitionsPageQuery($siteId: [QueryArgument]) {
    result: entry(section: "exhibitionsPage", siteId: $siteId) {
      id
      siteId
      title
      ... on exhibitionsPage_exhibitionsPage_Entry {
        contents: commonContents {
          ...CommonContents
        }
      }
    }
  }
`

export default EXHIBITIONS_PAGE_QUERY
