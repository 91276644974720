import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import {
  uiChangeCurrentInvertedUrl,
  uiChangeLoading,
} from 'data/redux/ui/actions'
import IMPRINT_PAGE_QUERY from 'data/queries/imprint-page'
import { mapLangToSiteId } from 'utils/i18n'
import Helmet from 'view/components/helmet/DefaultHelmet'
import ScrollToTop from 'view/components/scroll-to-top/ScrollToTop'
import NotFound from 'view/content/not-found/NotFound'
import Imprint from './Imprint'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
})

const mapDispatchToProps = {
  actionChangeCurrentInvertedUrl: uiChangeCurrentInvertedUrl,
  actionChangeLoading: uiChangeLoading,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ImprintProviderProps = ConnectedProps<typeof connector>

const ImprintProvider = ({
  projects,
  actionChangeCurrentInvertedUrl,
  actionChangeLoading,
}: ImprintProviderProps): ReactElement => {
  const { formatMessage, locale } = useIntl()

  const { data, loading } = useQuery(IMPRINT_PAGE_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    actionChangeCurrentInvertedUrl(
      locale === 'de' ? '/en/imprint' : '/de/impressum'
    )
  }, [locale])

  useEffect(() => {
    actionChangeLoading(loading)
  }, [loading])

  if (loading) {
    return null
  }

  if (data && data.result) {
    const textImprint = formatMessage({
      id: 'Navigation_Imprint',
      defaultMessage: 'Imprint',
    })

    const helmetParams = {
      title: textImprint,
      description: textImprint,
      url:
        locale === 'de' ? `${BASE_URL}de/impressum` : `${BASE_URL}en/imprint`,
    }

    return (
      <>
        <Helmet {...helmetParams} />
        <ScrollToTop />
        <Imprint locale={locale} projects={projects} {...data.result} />
      </>
    )
  }

  return <NotFound />
}

export default connector(ImprintProvider)
