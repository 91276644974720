import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { motion } from 'framer-motion'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import {
  uiChangeCurrentInvertedUrl,
  uiChangeHomeVisited,
  uiChangeLoading,
} from 'data/redux/ui/actions'
import HOME_QUERY from 'data/queries/home'
import { mapLangToSiteId } from 'utils/i18n'
import { motionPageVariants } from 'utils/motionVariants'
import Blocks from 'view/components/blocks/Blocks'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Intro from 'view/components/intro/Intro'
import ScrollToTop from 'view/components/scroll-to-top/ScrollToTop'

import './Home.scss'

const mapStateToProps = (state: RootState) => ({
  projects: state.content.projects,
  homeVisited: state.ui.homeVisited,
})

const mapDispatchToProps = {
  actionChangeCurrentInvertedUrl: uiChangeCurrentInvertedUrl,
  actionChangeHomeVisited: uiChangeHomeVisited,
  actionChangeLoading: uiChangeLoading,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type HomeProps = ConnectedProps<typeof connector>

const Home = ({
  projects,
  homeVisited,
  actionChangeCurrentInvertedUrl,
  actionChangeHomeVisited,
  actionChangeLoading,
}: HomeProps): ReactElement | null => {
  const { formatMessage, locale } = useIntl()

  const { data, loading } = useQuery(HOME_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    return () => {
      actionChangeHomeVisited(true)
    }
  }, [])

  useEffect(() => {
    actionChangeCurrentInvertedUrl(locale === 'de' ? '/en' : '/de')
  }, [locale])

  useEffect(() => {
    actionChangeLoading(loading)
  }, [loading])

  if (loading) {
    return null
  }

  if (data && data.result) {
    const textTitle = formatMessage({
      id: 'Home_Title',
      defaultMessage:
        'Willkommen bei der digitalen Publikation zur Ausstellung',
    })

    const { contents } = data.result

    const blocks = (
      <Blocks
        contents={contents}
        projects={projects}
        locale={locale}
        projectId={null}
        allowExpanders={true}
      />
    )

    const helmetParams = {
      url: locale === 'de' ? `${BASE_URL}/de` : `${BASE_URL}/en`,
    }

    return (
      <>
        <Helmet key="helmet" {...helmetParams} />
        {!homeVisited && <Intro />}
        <ScrollToTop />
        <motion.section
          className="Home"
          variants={motionPageVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div className="Home__title">
            <div className="Home__title__intro">
              {textTitle}
              <span>:</span>
            </div>
            <div className="Home__title__logo">Planet Digital</div>
          </div>
          <div className="Home__blocks">{blocks}</div>
        </motion.section>
      </>
    )
  }

  return null
}

export default connector(Home)
