import React, { ReactElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { handleContentLinks } from 'utils/handleContentLinks'

import './BlockMeta.scss'

const BlockMeta = ({ locale, paddingTop, related }): ReactElement | null => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const relatedFirst = related && related.length > 0 && related[0]

  if (relatedFirst) {
    const { title, contents } = relatedFirst

    let numberOfBlocks = 0
    const blocks = contents.map((item) => {
      if (item.hidden) return null

      numberOfBlocks++
      return (
        <div key={item.id} className="BlockMeta__grid__item">
          <div
            className="BlockMeta__grid__item__content"
            dangerouslySetInnerHTML={{ __html: item.content }}
            onClick={handleContentClick}
          />
        </div>
      )
    })

    const classes = classnames(
      'Block',
      paddingTop ? `h-padding-${paddingTop}` : '',
      'BlockMeta',
      `BlockMeta--${numberOfBlocks}`
    )

    return (
      <div className={classes}>
        <div className="BlockMeta__title">{title}</div>
        <div className="BlockMeta__grid">{blocks}</div>
      </div>
    )
  }

  return null
}

export default BlockMeta
