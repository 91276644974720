import { gql } from '@apollo/client'

import { COMMON_CONTENTS } from './fragments'

const HOME_QUERY = gql`
  ${COMMON_CONTENTS}
  query HomeQuery($siteId: [QueryArgument]) {
    result: entry(section: "home", siteId: $siteId) {
      id
      siteId
      title
      ... on home_home_Entry {
        contents: commonContents {
          ...CommonContents
        }
      }
    }
  }
`

export default HOME_QUERY
