import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import moment from 'moment'
import striptags from 'striptags'
import trim from 'trim'
import classnames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

import EVENTS_QUERY from 'data/queries/events'
import { handleContentLinks } from 'utils/handleContentLinks'
import { mapLangToSiteId } from 'utils/i18n'
import { motionEventsItemDetailVariants } from 'utils/motionVariants'
import Blocks from 'view/components/blocks/Blocks'
import BlockRelatedProject from 'view/components/blocks/BlockRelatedProject'
import Image from 'view/components/image/Image'

import './BlockEvents.scss'

const BlockEventsItem = ({
  locale,
  projects,
  open,
  title,
  date,
  time,
  type,
  archive,
  lead,
  text,
  link,
  linkText,
  linkBefore,
  related,
  thumb,
  contents,
}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState(open ? open : false)
  const navigate = useNavigate()
  const location = useLocation()
  const handleContentClick = (ev) =>
    handleContentLinks(ev, locale, navigate, location)

  const toggleActive = () => setActive(!active)

  useEffect(() => {
    if (active && ref && ref.current) {
      animateScroll.scrollTo(
        window.pageYOffset + ref.current.getBoundingClientRect().top - 10,
        {
          duration: 300,
          delay: 250,
          smooth: true,
        }
      )
    }
  }, [active])

  const timeClean = trim(
    striptags(time, ['br', 'em', 'i', 'strong', 'b', 'sup', 'sub', 'a'])
  )

  let dateSpan = null

  if (date) {
    const dateDayOfWeekName = moment(date, 'DD.MM.YYYY')
      .locale(locale)
      .format('dddd')
    const dateDayMonth = moment(date, 'DD.MM.YYYY')
      .locale(locale)
      .format('DD. MMMM')

    dateSpan = (
      <>
        <span>{dateDayOfWeekName},&nbsp;</span>
        <span>
          <strong>{dateDayMonth}</strong>
        </span>
        {time && (
          <span
            className="BlockEvents__item__teaser__date__time"
            dangerouslySetInnerHTML={{ __html: ', ' + timeClean }}
          />
        )}
      </>
    )
  }

  const teaserHtml = (
    <div className="BlockEvents__item__teaser__grid">
      <div className="BlockEvents__item__teaser__date">{dateSpan}</div>
      <div className="BlockEvents__item__teaser__title">{title}</div>
      <div className="BlockEvents__item__teaser__meta">
        <div
          className="BlockEvents__item__teaser__meta__type"
          dangerouslySetInnerHTML={{ __html: type }}
        />
        <div className="BlockEvents__item__teaser__meta__icon">
          {active ? '–' : '+'}
        </div>
      </div>
    </div>
  )
  let detailHtml = null

  if (archive) {
    // archive version
    detailHtml = (
      <div className="BlockEvents__item__detail__archive">
        <Blocks
          contents={contents}
          projects={projects}
          locale={locale}
          projectId={null}
          allowExpanders={true}
        />
      </div>
    )
  } else {
    // normal version
    const thumbFirst = thumb && thumb.length > 0 && thumb[0]
    let bottomHtml = null

    if (link || related.length > 0) {
      bottomHtml = (
        <div className="BlockEvents__item__detail__bottom">
          {link && (
            <div className="BlockEvents__item__detail__bottom__link">
              {linkBefore && (
                <span className="BlockEvents__item__detail__bottom__link__hint">
                  {linkBefore}
                </span>
              )}
              <a
                href={link}
                className="BlockEvents__item__detail__bottom__link__anchor"
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkText ? linkText : 'Link'}
              </a>
            </div>
          )}
          {related.length > 0 && (
            <div className="BlockEvents__item__detail__bottom__related">
              <BlockRelatedProject
                locale={locale}
                related={related}
                paddingTop="none"
              />
            </div>
          )}
        </div>
      )
    }

    detailHtml = (
      <div className="BlockEvents__item__detail__normal">
        <div className="BlockEvents__item__detail__grid">
          <div className="BlockEvents__item__detail__left">
            {thumbFirst && (
              <figure className="BlockEvents__item__detail__figure">
                <div
                  className="BlockEvents__item__detail__figure__ratio"
                  style={{
                    paddingTop: `${
                      (thumbFirst.height / thumbFirst.width) * 100
                    }%`,
                  }}
                />
                <Image
                  image={thumbFirst}
                  className="BlockEvents__item__detail__figure__img"
                />
              </figure>
            )}
          </div>
          <div className="BlockEvents__item__detail__right">
            {lead && (
              <div
                className="BlockEvents__item__detail__lead"
                dangerouslySetInnerHTML={{ __html: lead }}
                onClick={handleContentClick}
              />
            )}
            {text && (
              <div
                className="BlockEvents__item__detail__text"
                dangerouslySetInnerHTML={{ __html: text }}
                onClick={handleContentClick}
              />
            )}
            {bottomHtml}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="BlockEvents__item" ref={ref}>
      <div className="BlockEvents__item__teaser" onClick={toggleActive}>
        {teaserHtml}
      </div>

      <AnimatePresence>
        {active && (
          <motion.div
            variants={motionEventsItemDetailVariants}
            className="BlockEvents__item__detail"
            initial="initial"
            animate="visible"
            exit="exit"
          >
            {detailHtml}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const BlockEvents = ({ locale, paddingTop, projects }): ReactElement | null => {
  const { data, loading } = useQuery(EVENTS_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  if (loading) {
    return <span>Loading…</span>
  }

  if (data && data.results) {
    // find the next event based on moment
    let found = false
    const m = moment()

    const items = data.results.map((item) => {
      // parse date and compare if in the future meaning, negative seconds
      const dateTech = moment(item.dateTech)
      const open = m.diff(dateTech, 'minutes') <= 0 && !found

      if (open) {
        found = true
      }

      return (
        <BlockEventsItem
          key={item.id}
          locale={locale}
          open={open}
          projects={projects}
          {...item}
        />
      )
    })

    const classes = classnames(
      'Block',
      paddingTop ? `h-padding-${paddingTop}` : '',
      'BlockEvents'
    )

    return (
      <div className={classes}>
        {items && <div className="BlockBlockEvents__content">{items}</div>}
      </div>
    )
  }

  return null
}

export default BlockEvents
