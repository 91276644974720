import React, { ReactElement } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import Analytics from 'view/components/analytics/Analytics'
import CookieBanner from 'view/components/cookie-banner/CookieBanner'
import Loading from 'view/components/loading/Loading'
import Navigation from 'view/components/navigation/Navigation'

import Home from 'view/content/home/Home'
import Exhibitions from './content/exhibitions/ExhibitionsProvider'
import Imprint from './content/imprint/ImprintProvider'
import Press from 'view/content/press/PressProvider'
import Privacy from 'view/content/privacy/PrivacyProvider'
import ProjectIndex from 'view/content/project-index/ProjectIndex'
import Project from 'view/content/project/ProjectProvider'
import Tag from 'view/content/tag/TagProvider'
import NotFound from 'view/content/not-found/NotFound'

import 'style/globals/index.scss'

const App = (): ReactElement => {
  const location = useLocation()
  const state = location.state as { backgroundLocation?: Location }

  return (
    <main>
      <Analytics />
      <Loading />

      <AnimatePresence>
        {state?.backgroundLocation && (
          <Routes location={location}>
            <Route path="/:lang/tag/:slug" element={<Tag />} />
          </Routes>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <Routes
          location={state?.backgroundLocation || location}
          key={state?.backgroundLocation.pathname || location.pathname}
        >
          <Route path="/de" element={<Home />} />
          <Route path="/en" element={<Home />} />

          <Route path="/:lang/tag/:slug" element={<Tag />} />

          <Route path="/de/ausstellung" element={<Exhibitions />} />
          <Route path="/en/exhibition" element={<Exhibitions />} />

          <Route path="/de/impressum" element={<Imprint />} />
          <Route path="/en/imprint" element={<Imprint />} />

          <Route path="/de/presse" element={<Press />} />
          <Route path="/en/press" element={<Press />} />

          <Route path="/de/datenschutz" element={<Privacy />} />
          <Route path="/en/privacy" element={<Privacy />} />

          <Route path="/de/projekte" element={<ProjectIndex />} />
          <Route path="/en/projects" element={<ProjectIndex />} />

          <Route path="/de/projekt/:slug" element={<Project />} />
          <Route path="/en/project/:slug" element={<Project />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>

      <Navigation />
      <CookieBanner />
    </main>
  )
}

export default App
