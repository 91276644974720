import { gql } from '@apollo/client'

const PRESS_QUERY = gql`
  query PressQuery($siteId: [QueryArgument]) {
    results: entries(section: "press", siteId: $siteId) {
      id
      siteId
      ... on press_press_Entry {
        date: pressDate
          @formatDateTime(format: "d.m.Y", timezone: "Europe/Zurich")
        publisher: pressPublisher
        url: pressPublisherUrl
        thumb: pressThumb {
          id
          url @transform(handle: "press")
          title
          width @transform(handle: "press")
          height @transform(handle: "press")
        }
        lead: pressLead
        text: pressText
      }
    }
  }
`

export default PRESS_QUERY
