import { gql } from '@apollo/client'

const PROJECTS_QUERY = gql`
  query ProjectsQuery($siteId: [QueryArgument]) {
    results: entries(section: "projects", siteId: $siteId) {
      id
      siteId
      slug
      title
      ... on projects_project_Entry {
        indexHidden: projectIndexHidden
        meta: projectCaption
        abstract: projectAbstract
        thumb: projectThumb {
          id
          url @transform(handle: "thumb")
          largeUrl: url @transform(handle: "large")
          title
          width @transform(handle: "thumb")
          height @transform(handle: "thumb")
        }
      }
    }
  }
`

export default PROJECTS_QUERY
